import React from 'react';
import './LineItemTable.css';

import LineItemCell from '../LineItemCell/LineItemCell';

class LineItemTable extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="LineItemTable">
                {this.props.items.map( (item, index) => 
                    this.props.processedItems.has(item.id) ?
                        <LineItemCell imageUrl={item.image_url}
                                      title={item.title}
                                      variantTitle={item.variant_title}
                                      productId={item.product_id}
                                      price={item.price}
                                      border={index !== this.props.items.length - 1}
                                      whitelist={this.props.whitelist}
                                      lineItem={item}
                                      processed={true}
                                      order={this.props.order}
                                      orderTotalSize={this.props.items.length}
                                      link={'/exchange/' + this.props.order + '/' + item.id} />
                        :
                        <LineItemCell imageUrl={item.image_url}
                                      title={item.title}
                                      variantTitle={item.variant_title}
                                      productId={item.product_id}
                                      price={item.price}
                                      border={index !== this.props.items.length - 1}
                                      whitelist={this.props.whitelist}
                                      lineItem={item}
                                      processed={false}
                                      order={this.props.order}
                                      isMulti={this.props.isMulti}
                                      orderTotalSize={this.props.items.length}
                                      link={
                                        window.location.pathname.includes('-') ?
                                            `/exchange/${this.props.order}/${window.location.pathname.split('/').slice(-1)[0]}_${item.id}` :
                                            `/exchange/${this.props.order}/${item.id}`
                                      } />
                )}
            </div>
        )
    }
}

export default LineItemTable;
  
