import React from 'react';
import './HorizontalButtonGroup.css';

class HorizontalButtonGroup extends React.Component {

    render() {
        return (
            <div className='HorizontalButtonGroup'>
                <div className='HorizontalButtonGroup--Button'>{this.props.firstButton}</div>
                <div className='HorizontalButtonGroup--Button'>{this.props.secondButton}</div>
            </div>
        )
    }
}

export default HorizontalButtonGroup;
  
