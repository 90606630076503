import {React, useState, useCallback, useEffect} from 'react';
import './Admin.css';
import DataTable from '../../Components/DataTable/DataTable';
import Button from '../../Components/Button/Button';

import { getCookie } from '../../util/cookieHelpers';
import { downloadTableAsCsv } from '../../util/dataHelpers';

export default function Admin(props) {

  const [processButtonRowDisabled, setProcessButtonRowDisabled] = useState(true);
  const [selectedResources, setSelectedResources] = useState([]);

  /* Table */
  const [table, setTable] = useState([]);
  const [baseTable, setBaseTable] = useState([]);
  const headers = [
    '',
    'Order',
    'Name',
    'Actions',
    '🚚',
    'Exc',
    'Ref',
    'Rej',
    'Old Item',
    'New Item',
    'Track',
    'Email',
    'Created',
    'Label'
  ];

  const sortTableBy = (header) => {
    let headerDict = {
      'Order': 'order',
      'Name': 'name',
      'Actions': 'totalActions',
      '🚚': 'received',
      'Exc': 'exchanged',
      'Ref': 'refunded',
      'Rej': 'rejected',
      'Old Item': 'item',
      'New Item': 'newItem',
      'Email': 'email',
      'Created': 'dateCreated'
    }

    let elementClassList = document.querySelector(`.${header.replace(' ', '_')}`).classList;
    let direction = 'Ascending';
    if (elementClassList.contains('arrow-down')) {
      direction = 'Descending';
    }

    if (header === 'Name' || header === 'Old Item' || header === 'New Item' || header === 'Email') {
      let newTable = baseTable.slice(0);
      let headerReference = headerDict[header];
      newTable.sort(function(a, b){
        if (direction === 'Descending') {
          return a[headerReference].localeCompare(b[headerReference])
        } else if (direction === 'Ascending') {
          return b[headerReference].localeCompare(a[headerReference])
        }
      });
      setTable(newTable);
    } else if (header === 'Order' || header === 'Actions') {
      let newTable = baseTable.slice(0);
      let headerReference = headerDict[header];
      newTable.sort(function(a, b){
        // equal items sort equally
        if (a[headerReference] === b[headerReference]) {
            return 0;
        }

        // nulls sort after anything else
        if (a[headerReference] === null) {
            return 1;
        }
        if (b[headerReference] === null) {
            return -1;
        }

        if (header === 'Order') {
          let orderNumberA = parseInt(a[headerReference].replace('#', ''));
          let orderNumberB = parseInt(b[headerReference].replace('#', ''));

          if (direction === 'Descending') {
            return orderNumberA < orderNumberB ? -1 : 1;
          } else if (direction === 'Ascending') {
            return orderNumberA < orderNumberB ? 1 : -1;
          }
        } 

        if (direction === 'Descending') {
          return a[headerReference] < b[headerReference] ? -1 : 1;
        } else if (direction === 'Ascending') {
          return a[headerReference] < b[headerReference] ? 1 : -1;
        }
      });
      setTable(newTable);
    } else if (header === '🚚' || header === 'Exc' || header === 'Ref' || header === 'Rej') {
      let newTable = baseTable.slice(0);
      let headerReference = headerDict[header];
      newTable.sort((a,b) => {
        if (direction === 'Descending') {
          if (a[headerReference]) {
            return -1;
          }

          if (b[headerReference]) {
            return 1;
          }
        } else if (direction === 'Ascending') {
          if (!a[headerReference]) {
            return -1;
          }

          if (!b[headerReference]) {
            return 1;
          }
        }
        return 1;
      });
      setTable(newTable);
    } else if (header === 'Created') {
      let newTable = baseTable.slice(0);
      let headerReference = headerDict[header];
      newTable.sort((date1, date2) => {
        let d1 = date1.dateCreated.split('-');
        let d2 = date2.dateCreated.split('-');

        let a = `${d1[2]}${d1[0]}${d1[1]}`;
        let b = `${d2[2]}${d2[0]}${d2[1]}`;

        if (direction === 'Descending') {
          return a > b ? 1 : a < b ? -1 : 0;
        } else if (direction === 'Ascending') {
          return a > b ? -1 : a < b ? 1 : 0;
        }
      });
      setTable(newTable);
    }

    return(direction);
  }

  const getTrackingLabel = (trackingNumber) => {
    let url = window.location.origin + "/existing_shipping_label_url?tracking_number=" + trackingNumber;

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Content-tt': getCookie('tt'),
      },
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data.shipping_label_url) {
        window.open(data.shipping_label_url, '_blank').focus();
      } else {
        alert('Shipping label not found.');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  /* End Table */

  /* Cancel Modal */

  const [cancelActive, setCancelActive] = useState(false);
  const cancelHandleOpen = useCallback(() => setCancelActive(true), []);
  const cancelHandleClose = useCallback(() => {
    setCancelActive(false);
  }, []);

  const handleCancel = () => {
    let url = window.location.origin + '/admin/delete';
    let bodyData = {
      selectedRows: selectedResources
    }
    cancelHandleClose();
    setCancelActive(false);
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Content-tt': getCookie('tt'),
      },
      'body': JSON.stringify(bodyData),
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      alert('Those have been canceled. Please refresh to see changes in your table.');
    })
    .catch(error => {
      alert(error);
    });
  }

  const renderCancelModal = () => {
    return (
      <div className={'AdminModal'}>
        <div className={'AdminModalInner'}>
          <p>Cancel the selected returns/exchanges</p>
          <p>
            Canceling these selected returns/exchanges will delete them from our database forever. This is <b>NOT</b> reversable, so make sure this is what you wanted to do. Any incomplete returns/exchanges that are deleted will not be processed on delivery. Any already completed returns/exchanges that are deleted will be removed from our database, but this will <b>NOT</b> reverse the return or the exchange.  
          </p>
          <Button backgroundColor={'var(--indie-ridge-red)'}
                  textColor={'white'}
                  buttonText={'Yes, Cancel'}
                  onClick={ () => {
                      handleCancel()
                    }
                  } />
          <Button backgroundColor={'white'}
                  textColor={'var(--indie-ridge-red)'}
                  buttonText={'No, Do Not Cancel'}
                  border={'solid 2px var(--indie-ridge-red)'}
                  onClick={ () => {
                      cancelHandleClose()
                    }
                  } />
        </div>
      </div>
    )
  }

  /* End Modal */

  /* Process Manually Modal */
  
  const [processActive, setProcessActive] = useState(false);
  const processHandleOpen = useCallback(() => setProcessActive(true), []);
  const processHandleClose = useCallback(() => setProcessActive(false), []);

  const handleProcess = () => {
    let url = window.location.origin + '/admin/process';
    let bodyData = {
      selectedRows: selectedResources
    }
    processHandleClose();
    setProcessActive(false);
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Content-tt': getCookie('tt'),
      },
      'body': JSON.stringify(bodyData),
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      alert('Those have been processed. Please refresh to see changes in your table');
    })
    .catch(error => {
      alert(error);
    });
  }

  const handleSelect = (id) => {
    let ids = [];
    let inputs = document.querySelectorAll('.datarow:checked');
    inputs.forEach((ele) => {
      ids.push(ele.dataset.actionId);
    });
    setSelectedResources(ids);
  }

  const renderProcessModal = () => {
    return (
      <div className={'AdminModal'}>
        <div className={'AdminModalInner'}>
          <p>Manually process the selected returns/exchanges</p>
          <p>
            Processing these selected returns/exchanges will force flag them as delivered. This will trigger those returns/exchanges to process as though it was delivered organically. This process should be safe to conduct but is <b>NOT</b> reversable.
          </p>
          <Button backgroundColor={'var(--indie-ridge-red)'}
                  textColor={'white'}
                  buttonText={'Approve'}
                  onClick={ () => {
                      handleProcess()
                    }
                  } />
          <Button backgroundColor={'white'}
                  textColor={'var(--indie-ridge-red)'}
                  buttonText={'Cancel'}
                  border={'solid 2px var(--indie-ridge-red)'}
                  onClick={ () => {
                      processHandleClose()
                    }
                  } />
        </div>
      </div>
    )
  }

  /* End Modal */

  /* Reject Manually Modal */

  const [rejectActive, setRejectActive] = useState(false);
  const rejectHandleOpen = useCallback(() => setRejectActive(true), []);
  const rejectHandleClose = useCallback(() => setRejectActive(false), []);
  const handleRejection = () => {
    let url = window.location.origin + '/admin/reject';
    let bodyData = {
      selectedRows: selectedResources
    }
    rejectHandleClose();
    setProcessActive(false);
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Content-tt': getCookie('tt'),
      },
      'body': JSON.stringify(bodyData),
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      alert('Those have been rejected. Please refresh to see changes in your table');
    })
    .catch(error => {
      alert(error);
    });
  }

  const renderRejectionModal = () => {
    return (
      <div className={'AdminModal'}>
        <div className={'AdminModalInner'}>
          <p>Manually reject the selected returns/exchanges</p>
          <p>
            Rejecting these selected returns/exchanges will flag them as rejected. This will prevent them from triggering any automatic behavior. You must follow up manually through Shopify on any rejected order that you wish to take further action on. (For example: Reject an automatic refund to give a lesser refund through Shopify).
          </p>
          <Button backgroundColor={'var(--indie-ridge-red)'}
                  textColor={'white'}
                  buttonText={'Reject'}
                  onClick={ () => {
                      handleRejection()
                    }
                  } />
          <Button backgroundColor={'white'}
                  textColor={'var(--indie-ridge-red)'}
                  buttonText={'Cancel'}
                  border={'solid 2px var(--indie-ridge-red)'}
                  onClick={ () => {
                      rejectHandleClose()
                    }
                  } />
        </div>
      </div>
    )
  }

  /* End Modal */

  /* Scan Modal */

  const [scanFoundActive, setScanFoundActive] = useState(false);
  const [scanData, setScanData] = useState();

  const handleScanAccept = () => {
    let url = window.location.origin + '/admin/process';
    let bodyData = {
      selectedRows: [scanData.id]
    }
    processHandleClose();
    setScanFoundActive(false);
    setScanData({});
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Content-tt': getCookie('tt'),
      },
      'body': JSON.stringify(bodyData),
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      alert('Those have been processed. Please refresh to see changes in your table');
    })
    .catch(error => {
      alert(error);
    });
  }

  const handleScanReject = () => {
    let url = window.location.origin + '/admin/reject';
    let bodyData = {
      selectedRows: [scanData.id]
    }
    setScanFoundActive(false);
    setScanData({});
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Content-tt': getCookie('tt'),
      },
      'body': JSON.stringify(bodyData),
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      alert('Those have been rejected. Please refresh to see changes in your table');
    })
    .catch(error => {
      alert(error);
    });
  }

  const renderScanFound = () => {
    return(
      <div className={'scanModal'}>
        <p>Name: {scanData.name}</p>
        <p>Email: {scanData.email}</p>
        <p>Date Requested: {scanData.dateRequested}</p>
        <p className={'scanActionType'}>{scanData.actionType}:</p>
        <p className={'scanOrderNumber'}>{scanData.orderNumber} - {scanData.discounts}</p>
        <div className={'scanProducts'}>
          {scanData && scanData.lineItems ? scanData.lineItems.map((item) => (
            <p>{item.productTitle}</p>
          )) : ''}
        </div>
        <div className={'scanButtonWrapper'}>
          <button className={'scanOkayButton'}
                  onClick={() => {
                    handleScanAccept()
                  }}>OK</button>
          <button className={'scanRejectButton'}
                  onClick={() => {
                    handleScanReject()
                  }}>SEND TO<br/>MANUAL</button>
        </div>
        <button className={'scanCloseButton'} onClick={() => {
          setScanFoundActive(false);
          setScanData({});
        }}>x</button>
      </div>
    )
  }

  // 1Z96W14X1235755607

  const [scanActive, setScanActive] = useState(false);
  const scanHandleOpen = useCallback(() => setScanActive(true), []);
  const scanHandleClose = useCallback(() => setScanActive(false), []);
  const handleScan = (event) => {
    let scan = document.querySelector('#AdminScanInput').value;
    if (scan.length > 5) {
      let scanList = [];
      for (var i = 0; i < baseTable.length; i++) {
        if(baseTable[i].tracking_number === scan) {
          scanList.push(baseTable[i]);
        }
      }
      let returnedScanData = {
        'name': scanList[0].name,
        'email': scanList[0].email,
        'dateRequested': scanList[0].dateCreated,
        'actionType': scanList[0].newItem === 'Refund' ? 'RETURN' : 'EXCHANGE',
        'orderId': scanList[0].order_id,
        'orderNumber': scanList[0].order,
        'discounts': scanList[0].discountApplications.length > 0 ? 
                     `Promo[${scanList[0].discountApplications[0].code}] ${scanList[0].discountApplications[0].value_type === 'fixed_amount' ? '$' : ''}${scanList[0].discountApplications[0].value}${scanList[0].discountApplications[0].value_type === 'percentage' ? '%' : ''} off` : '',
        'lineItems': []
      }
      for (var i = 0; i < scanList.length; i++) {
        let oldItem = {
          'productTitle': scanList[i].item
        }
        console.log(oldItem);
        returnedScanData.lineItems.push(oldItem);
      }
      setScanData(returnedScanData);
      setScanFoundActive(true);
    }
  }

  const renderScanModal = () => {
    return (
      <div className={'AdminModal'}>
        <div className={'AdminModalInner'}>
          {
            scanFoundActive && scanData ? 
              renderScanFound()
            : <>
                <p>Please scan the label onto the input below...</p>
                <input type="text" id={'AdminScanInput'} onChange={() => {handleScan()}} />
                <Button backgroundColor={'white'}
                        textColor={'var(--indie-ridge-red)'}
                        buttonText={'Cancel'}
                        border={'solid 2px var(--indie-ridge-red)'}
                        onClick={ () => {
                            scanHandleClose();
                            setScanData({});
                          }
                        } />
              </>
          }
        </div>
      </div>
    )
  }

  /* End Modal */

  useEffect(() => {
    let url = window.location.origin + "/r_e";

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Content-tt': getCookie('tt'),
      },
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      let combined = data.returns.concat(data.exchanges);

      combined.sort((date1, date2) => {
        let d1 = date1.dateCreated.split('-');
        let d2 = date2.dateCreated.split('-');

        let a = `${d1[2]}${d1[0]}${d1[1]}`;
        let b = `${d2[2]}${d2[0]}${d2[1]}`;

        return a > b ? 1 : a < b ? -1 : 0;
      });

      setTable(combined.reverse());
      setBaseTable(combined);
    })
    .catch(error => {
        console.log(error);
    });
  }, []);

  useEffect(() => {
    if (selectedResources.length > 0) {
      setProcessButtonRowDisabled(false);
    } else {
      setProcessButtonRowDisabled(true);
    }
  }, [selectedResources]);

	return (
		<div className={'AdminTable'}>
      <div className={'AdminButtonWrapper'}>
        <div className={'AdminSearchWrapper'}>
          <input type="text" className={'AdminSearchInput'} id="search" placeholder="Search..." onChange={() => {
            const search_value = document.querySelector('#search').value.toLowerCase();
            let newTable = [];
            if (search_value !== '') {
              baseTable.forEach((ele) => {
                if(
                    ele.name?.toLowerCase().includes(search_value) || 
                    ele.order?.toLowerCase().includes(search_value) || 
                    ele.order_id?.toLowerCase().includes(search_value) || 
                    ele.item?.toLowerCase().includes(search_value) || 
                    ele.newItem?.toLowerCase().includes(search_value) || 
                    ele.tracking_number?.toLowerCase().includes(search_value) || 
                    ele.email?.toLowerCase().includes(search_value) || 
                    ele.dateCreated?.toLowerCase().includes(search_value)
                  ) {
                  newTable.push(ele);
                }
              });
              setTable(newTable);
            } else {
              setTable(baseTable);
            }
            
          }} />
        </div>
        <Button backgroundColor={'var(--indie-ridge-red)'}
                textColor={'white'}
                buttonText={'God Mode'}
                className={'AdminButton'}
                onClick={ () => {
                   window.open('/order_search', '_blank').focus();
                  }
                } />
        <Button backgroundColor={'var(--indie-ridge-red)'}
                textColor={'white'}
                buttonText={'Approve'}
                className={`AdminButton ${selectedResources.length > 0 ? '' : 'AdminDisabledButton'}`}
                onClick={ () => {
                   setProcessActive(true);
                  }
                } />
        <Button backgroundColor={'white'}
                textColor={'var(--indie-ridge-red)'}
                buttonText={'Cancel'}
                border={'solid 2px var(--indie-ridge-red)'}
                className={`AdminButton ${selectedResources.length > 0 ? '' : 'AdminDisabledButton'}`}
                onClick={ () => {
                    setCancelActive(true);
                  }
                } />
        <Button backgroundColor={'white'}
                textColor={'var(--indie-ridge-red)'}
                buttonText={'Reject'}
                border={'solid 2px var(--indie-ridge-red)'}
                className={`AdminButton ${selectedResources.length > 0 ? '' : 'AdminDisabledButton'}`}
                onClick={ () => {
                    setRejectActive(true);
                  }
                } />
        <button className={'AdminDownloadButton'} 
                onClick={ () => {
                    downloadTableAsCsv('DataTable');
                  }
                } ><img src={process.env.PUBLIC_URL + '/download-icon.png'} /></button>
        <button className={'AdminScanButton'} 
                onClick={ () => {
                    setScanActive(true);
                  }
                } ><img src={process.env.PUBLIC_URL + '/scan.png'} /></button>
      </div>
      <DataTable headers={headers} 
                 table={table}
                 trackingHandler={getTrackingLabel}
                 selectCallback={handleSelect}
                 sortBy={sortTableBy} />
      {
        cancelActive ? 
          renderCancelModal()
        : ''
      }
      {
        processActive ? 
          renderProcessModal()
        : ''
      }
      {
        rejectActive ?
          renderRejectionModal()
        : ''
      }
      {
        scanActive ?
          renderScanModal()
        : ''
      }
    </div>
	)
}
