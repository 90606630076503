import React, { useState, useEffect } from 'react';
import './ImageDropdownInput.css';

function ImageDropdownInput(props) {
    const sortArray = (objectId, array) => {
        let currentSelected = objectId;
        let newArray = [];

        array.forEach((ele, index) => {
            if(ele.id !== currentSelected) {
                newArray.push(ele);
            } else {
                newArray.unshift(ele);
            }
        });

        return(newArray);
    }

    const sortedProductArray = sortArray(props.selectedProductId, props.similar_products);
    const sortedVariantArray = sortArray(props.selectedVariantId, props.variants);
    const [defaultShowing, setDefaultShowing] = useState(props.isMagic);

    useEffect(() => {
       document.querySelector('#ImageDropdownInput-Product .ImageDropdownInput--Selectedtrue').style.display = 'flex';
    }, [defaultShowing]);

    // const [selectedState, setSelectedState] = useState({
    //     selectedProductId: props.selectedProductId,
    //     selectedVariantId: props.selectedVariantId,
    //     selectedProductImage: props.mainImage,
    //     productArray: sortedProductArray,
    //     variantArray: sortedVariantArray,
    //     selectedProductTitle: props.selectedProductTitle,
    //     selectedVariantTitle: props.selectedVariantTitle
    // });

    const [selectedState, setSelectedState] = useState({
        selectedProductId: props.isMagic ? '' : props.selectedProductId,
        selectedVariantId: props.isMagic ? '' : props.selectedVariantId,
        selectedProductImage: props.isMagic ? '' : props.mainImage,
        productArray: sortedProductArray,
        variantArray: sortedVariantArray,
        selectedProductTitle: props.isMagic ? '' : props.selectedProductTitle,
        selectedVariantTitle: props.isMagic ? '' : props.selectedVariantTitle
    });

    const isToggleProduct = (toggle, title, productId, variants, image, callback) => {
        if (toggle) {
            toggleDropdown('#ImageDropdownInput-Product');
        } else {
            selectProductElement(productId, title, variants, image, callback);
        }
    }

    const isToggleVariant = (toggle, title, variantId, callback) => {
        if (toggle) {
            toggleDropdown('#ImageDropdownInput-Variant');
        } else {
            selectVariantElement(variantId, title, callback);
        }
    }

    const toggleDropdown = (toggleId) => {
        let dropdownToggle = document.querySelector(toggleId + ' .ImageDropdownInput--Selectedtrue');

        if (dropdownToggle) {
            if (dropdownToggle.classList.contains('ImageDropdownInput--Collapsed')) {
                dropdownToggle.classList.remove('ImageDropdownInput--Collapsed');
                dropdownToggle.classList.add('ImageDropdownInput--Expanded');

                document.querySelectorAll(toggleId + ' .ImageDropdownInput--Selectedfalse').forEach((ele, index) => {
                    ele.style.display = 'flex';
                });
            } else if (dropdownToggle.classList.contains('ImageDropdownInput--Expanded')) {
                dropdownToggle.classList.remove('ImageDropdownInput--Expanded');
                dropdownToggle.classList.add('ImageDropdownInput--Collapsed');

                document.querySelectorAll(toggleId + ' .ImageDropdownInput--Selectedfalse').forEach((ele, index) => {
                    ele.style.display = 'none';
                });
            }
        }
    }

    const getFirstAvailable = (variants) => {
        for(var i = 0; i < variants.length; i++) {
            if (variants[i].inventory_quantity > 0) {
                return(variants[i]);
            }
        }
        return(variants[0])
    }

    const selectProductElement = (optionId, title, variants, image, callback) => {
        let sortedProductArray = sortArray(optionId, props.similar_products);
        let firstAvailableVariant = getFirstAvailable(variants);
        let sortedVariantArray = sortArray(firstAvailableVariant.id, variants);
        let hoistState = {
            selectedProductId: optionId,
            selectedVariantId: firstAvailableVariant.id,
            selectedProductImage: image,
            productArray: sortedProductArray,
            variantArray: sortedVariantArray,
            selectedProductTitle: title,
            selectedVariantTitle: firstAvailableVariant.title
        };

        setSelectedState(hoistState);

        if (defaultShowing){
            setDefaultShowing(false);
        } 

        toggleDropdown('#ImageDropdownInput-Product');
        callback(hoistState);
    }

    const selectVariantElement = (optionId, title, callback) => {
        let sortedVariantArray = sortArray(optionId, selectedState.variantArray);
        let hoistState = selectedState;
        hoistState.selectedVariantId = optionId;
        hoistState.variantArray = sortedVariantArray;
        hoistState.selectedVariantTitle = title;

        setSelectedState({
            ...selectedState,
            selectedVariantId: optionId,
            variantArray: sortedVariantArray,
            selectedVariantTitle: title
        });

        toggleDropdown('#ImageDropdownInput-Variant');
        callback(hoistState);
    }

    const renderDefaultVariant = () => {
        if (selectedState.selectedVariantId === '' && !defaultShowing) {
            return (
                <div className={`ImageDropdownInput--OptionContainer ImageDropdownInput--OptionVariant ImageDropdownInput--Default ImageDropdownInput--Selectedtrue ImageDropdownInput--Collapsed`} 
                     onClick={() => isToggleVariant(true, '', '', props.onChange)}
                     >
                    <div className='ImageDropdownInput--OptionTitle'>
                        <p>SELECT YOUR CURRENT SIZE</p>
                    </div>
                    <div className='ImageDropdownInput--OptionChevronContainer'>
                        <div className='ImageDropdownInput--OptionChevron'></div>
                    </div>
                </div>
            )
        }
    }

    const renderDefaultProduct = () => {
        if (selectedState.selectedProductId === '') {
            return (
                <div className={`ImageDropdownInput--OptionContainer ImageDropdownInput--Default ImageDropdownInput--Selectedtrue ImageDropdownInput--Collapsed`} 
                     onClick={() => isToggleProduct(true, '', '', [], '', props.onChange)}
                     >
                    <div className='ImageDropdownInput--OptionTitle'>
                        <p>SELECT YOUR CURRENT PRODUCT</p>
                    </div>
                    <div className='ImageDropdownInput--OptionChevronContainer'>
                        <div className='ImageDropdownInput--OptionChevron'></div>
                    </div>
                </div>
            )
        }
    }

    const createVariants = () => {
        if (!defaultShowing) {
            return(
                <div id='ImageDropdownInput-Variant' className='ImageDropdownInput'>
                    <div className='ImageDropdownInput--LabelContainer'>
                        <p className='RobotoMedium'>{props.isMagic ? 'What size do you currently have?' : 'SELECT NEW SIZE:'}</p>
                    </div>
                    {renderDefaultVariant()}
                    {selectedState.variantArray.map( option => 
                        <div className={`ImageDropdownInput--OptionContainer ImageDropdownInput--OptionVariant ImageDropdownInput--Selected${option.id === selectedState.selectedVariantId} ImageDropdownInput--Collapsed`} 
                             data-variant-id={option.id} 
                             data-available={props.isMagic ? 10 : option.inventory_quantity > 0}
                             onClick={() => isToggleVariant(option.id === selectedState.selectedVariantId, option.title, option.id, props.onChange)}
                             >
                            <div className='ImageDropdownInput--OptionImage'>
                                <img src={selectedState.selectedProductImage} alt='Main Product Image'/>
                            </div>
                            <div className='ImageDropdownInput--OptionTitle'>
                                <p>{option.title}</p>
                            </div>
                            <div className='ImageDropdownInput--OptionChevronContainer'>
                                <div className='ImageDropdownInput--OptionChevron'></div>
                            </div>
                        </div>
                    )}
                </div>
            )
        }
    }

    const createProducts = () => {
        let label = ''
        if (!props.isMagic) {
            label = <div className='ImageDropdownInput--LabelContainer'>
                        <p className='RobotoMedium'>Or Select Different Style:</p>
                    </div>
        }
        return(
            <div id='ImageDropdownInput-Product' className='ImageDropdownInput'>
                {label}
                {renderDefaultProduct()}
                {selectedState.productArray.map( option => 
                    <div className={`ImageDropdownInput--OptionContainer ImageDropdownInput--Selected${option.id === selectedState.selectedProductId} ImageDropdownInput--Collapsed`} 
                         data-product-id={option.id}
                         data-variants={option.variants} 
                         onClick={() => isToggleProduct(option.id === selectedState.selectedProductId, option.title, option.id, option.variants, option.image.src, props.onChange)}
                         >
                        <div className='ImageDropdownInput--OptionImage'>
                            <img src={option.image.src} alt='Main Product Image'/>
                        </div>
                        <div className='ImageDropdownInput--OptionTitle'>
                            <p>{option.title !== undefined ? option.title.replace('Motorcycle Boots', '').replace('Motorcycle Gloves', '').replace(' - ', '') : ''}</p>
                        </div>
                        <div className='ImageDropdownInput--OptionChevronContainer'>
                            <div className='ImageDropdownInput--OptionChevron'></div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const createMarkup = () => {
        if (props.isMagic) {
            return(
                <>
                    {createProducts()}
                    {createVariants()}
                </>
            )
        } else {
            return(
                <>
                    {createVariants()}
                    {createProducts()}
                </>
            )
        }
    }

    return (
        <div className='ImageDropdownInput--Container'>
            {createMarkup()}
        </div>
    )
}

export default ImageDropdownInput;
  
