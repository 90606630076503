import {React, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import './OrderDetails.css';

import TextHeaderWithBackground from '../../Components/TextHeaderWithBackground/TextHeaderWithBackground';
import OrderInformation from '../../Components/OrderInformation/OrderInformation';
import LineItemTable from '../../Components/LineItemTable/LineItemTable';
import SeparatorLine from '../../Components/SeparatorLine/SeparatorLine';
import LoadingCircle from '../../Components/LoadingCircle/LoadingCircle';
import Button from '../../Components/Button/Button';
import MultiConfirmationModal from '../../Components/MultiConfirmationModal/MultiConfirmationModal';

import { getCookie } from '../../util/cookieHelpers';

export default function OrderDetails(props) {
  const backgroundColor = 'black';
  const textColor = 'white';
  const [topText, setTopText] = useState('');
  const [lineItems, setLineItems] = useState([]);
  const [whitelist, setWhitelist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState({});
  const [orderId, setOrderId] = useState('');
  const [lineItemString, setLineItemString] = useState([]);

  const [lineItemsAlreadyProcessed, setLineItemsAlreadyProcessed] = useState(() => new Set());

  const getOrder = (orderIdParam) => {
    let url = window.location.origin + '/order';
    let bodyData = {
      order: {
        order_id: orderIdParam,
      },
      token: {
        tt: getCookie('tt')
      }
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyData),
    })
    .then((response) => {
      if (response.status === 401) {
        window.location.href = '/orders';
      } else {
        return response.json();
      }
    })
    .then((data) => {
      console.log(data);
      if (data.error_message === 'Order not found.') {
        window.location.href = '/';
      } else {
        setOrderDetails({
          email: data.email,
          name: data.shipping_address.name,
          processed_at: data.processed_at,
          total_price: data.total_price,
          phone: data.shipping_address.phone,
          address_one: data.shipping_address.address1,
          address_two: data.shipping_address.address2,
          city: data.shipping_address.city,
          zip: data.shipping_address.zip,
          province: data.shipping_address.province
        });
        setLineItems(data.line_items);
        setTopText(`ORDER ${data.name} (${data.line_items.length} ${ data.line_items.length > 1 ? 'Items' : 'Item'})`);
        setLoading(false);
      }
    })
    .catch(error => {
        // handle the error
    });
  }

  const getWhitelist = () => {
    let url = window.location.origin + '/collections';
    let bodyData = {
      tt: getCookie('tt')
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyData),
    })
    .then((response) => {
      if (response.status === 401) {
        window.location.href = '/';
      } else {
        return response.json();
      }
    })
    .then((data) => {
      if (data.error_message === 'Order not found.') {
        window.location.href = '/';
      } else {
        let fullList = data.gloves.concat(data.boots);
        setWhitelist(fullList);
      }
    })
    .catch(error => {
        // handle the error
    });
  }

  const renderOrderInformation = () => {
    if (orderDetails) {
      return (
        <>
          <OrderInformation email={orderDetails.email}
                            name={orderDetails.name}
                            processed_at={orderDetails.processed_at}
                            total_price={orderDetails.total_price}
                            phone={orderDetails.phone}
                            address_one={orderDetails.address_one}
                            address_two={orderDetails.address_two}
                            city={orderDetails.city}
                            zip={orderDetails.zip}
                            province={orderDetails.province} /> 
        </>
      )
    }
    return (
      <></>
    )
  }

  const renderSubmitAlreadyProcessedButton = () => {
    if (props.multi) {
      return (
        <div className={'submitAlreadyProcessed'}>
          <Button backgroundColor={'var(--indie-ridge-red)'}
                  textColor={'white'}
                  buttonText={'Submit Exchange/Return'}
                  onClick={() => {
                    document.getElementById('modal').style.display = 'flex';
                    ReactDOM.render(<MultiConfirmationModal order={orderId} />, document.getElementById('modal'));
                  }} />
        </div>
      )
    }
    return (
      <></>
    )
  }

  useEffect(() => {
    getWhitelist();
    if (props.multi){
      const orderIdTemp = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
      const lineItemStringTemp = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
      setOrderId(orderIdTemp);
      setLineItemString(lineItemStringTemp);

      const processedPairs = lineItemStringTemp.split('_');
      const processedLineItems = new Set(processedPairs.map(a => parseInt(a.split('-')[0]) ) );
      setLineItemsAlreadyProcessed(processedLineItems);
      setOrderId(orderIdTemp);
      getOrder(orderIdTemp);
    } else {
      const orderIdTemp = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
      setOrderId(orderIdTemp);
      getOrder(orderIdTemp);
    }
  }, []);

  if (loading) {
    return (
      <div className="OrderDetails">
        <LoadingCircle /> 
      </div>
    )
  }

  return (
    <div className="OrderDetails">
      <TextHeaderWithBackground topText={topText} backgroundColor={backgroundColor} textColor={textColor} />
      <LineItemTable order={orderId} 
                     items={lineItems} 
                     processedItems={lineItemsAlreadyProcessed}
                     whitelist={new Set(whitelist.map(a => a.id))}
                     isMulti={props.multi} />
      <SeparatorLine />
      {renderOrderInformation()}
      {renderSubmitAlreadyProcessedButton()}
    </div>
  )
}
