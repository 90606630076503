import React from 'react';
import './ProductQuickInfoCell.css';

class ProductQuickInfoCell extends React.Component {

    render() {
        return (
            <div className='ProductQuickInfoCell'>
                <div className='ProductQuickInfoCell--ImageContainer'>
                    <img className='ProductQuickInfoCell--Image' src={this.props.image} alt={this.props.style} />
                </div>
                <div className='ProductQuickInfoCell--StyleContainer'>
                    <p className='ProductQuickInfoCell--Text Roboto'>Style:</p>
                    <p className='ProductQuickInfoCell--Title RobotoMedium'>{this.props.style.replace('Motorcycle Gloves', '').replace('Motorcycle Boots', '').replace(' - ', '')}</p>
                </div>
                <div className='ProductQuickInfoCell--SizeContainer'>
                    <p className='ProductQuickInfoCell--Text Roboto'>Size:</p>
                    <p className='ProductQuickInfoCell--Title RobotoMedium'>{this.props.size}</p>
                </div>
            </div>
        )
    }
}

export default ProductQuickInfoCell;
  
