import React from 'react';
import './DropdownInput.css';

class DropdownInput extends React.Component {

    render() {
        return (
            <select className='DropdownInput'>
                {this.props.options.map( (option, index) => 
                    <option value={option}>{option}</option>
                )}
            </select>
        )
    }
}

export default DropdownInput;
  
