import './App.css';

import Header from './Components/Header/Header';

import Homepage from './Screens/Homepage/Homepage';
import Exchange from './Screens/Exchange/Exchange';
import MagicExchange from './Screens/MagicExchange/MagicExchange';
import OrderDetails from './Screens/OrderDetails/OrderDetails';
import OrderHistory from './Screens/OrderHistory/OrderHistory';
import OrderSearch from './Screens/OrderSearch/OrderSearch';
import AuthEmail from './Screens/AuthEmail/AuthEmail';
import Admin from './Screens/Admin/Admin';

import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {useState, useEffect} from 'react';

function App() {
  return (
    <>
      <Helmet>
        <script defer="defer" src="https://maps.googleapis.com/maps/api/js?key=AIzaSyB_ip0lcfHUVW5unwSnOfRsKGMLq4SV9ZU&libraries=places&callback=initMap"></script>
        <title>Indie Ridge Returns & Exchanges</title>
      </Helmet>
      <Header />
      <div id='main-content'>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/admin' element={<Admin />} />
          <Route path='/orders' element={<OrderHistory />} />
          <Route path='/auth_email' element={<AuthEmail />} />
          <Route path='/order/:id' element={<OrderDetails />} />
          <Route path='/order/:id/:id' element={<OrderDetails multi={true} />} />
          <Route path='/exchange/:id/:id' element={<Exchange />} />
          <Route path='/exchange/:id/:id/:id' element={<Exchange multi={true} />} />
          <Route path='/exchange/magic/:id' element={<Exchange magic={true} />} />
          <Route path='/magic_exchange' element={<MagicExchange />} />
          <Route path='/order_search' element={<OrderSearch />} />
        </Routes>
      </div>
      <div id='modal'></div>
    </>
  );
}

export default App;
