import React from 'react';
import './TextInput.css';

class TextInput extends React.Component {

    render() {
        return (
            <>
                <label for='TextInput' className='TextInput--Label'>
                    {this.props.label}
                </label>
                <textarea name='TextInput' class='TextInput' placeholder='Type here...'>
                    
                </textarea>
            </>
        )
    }
}

export default TextInput;
  
