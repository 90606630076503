import React from 'react';
import './OrderTable.css';

import OrderCell from '../OrderCell/OrderCell';

class OrderTable extends React.Component {

    render() {
        return (
            <div className="OrderTable">
                {this.props.orders.map( order => 
                    <OrderCell orderData={order} link={'/order/' + order.order_id} />
                )}
            </div>
        )
    }
}

export default OrderTable;
  
