import {React, useState} from 'react';
import ReactDOM from 'react-dom';
import './ReturnConfirmationModal.css';

import ParagraphWithHeader from '../ParagraphWithHeader/ParagraphWithHeader';
import Button from '../Button/Button';
import DropdownInput from '../DropdownInput/DropdownInput';
import TextInput from '../TextInput/TextInput';
import ProductQuickInfoCell from '../ProductQuickInfoCell/ProductQuickInfoCell';
import LoadingCircle from '../LoadingCircle/LoadingCircle';

import { getCookie } from '../../util/cookieHelpers';

export default function ReturnConfirmationModal(props) {
    const [miniModal, setMiniModal] = useState(false);

    const paragraphHeader = props.isMulti ? 
                        'Are you sure you would like to return this item from this order?' : 'Are you sure you would like to return this item?';

    const checkmark = process.env.PUBLIC_URL + '/check.png';

    const topBarCloseAction = () => {
        document.getElementById('modal').style.display = 'none';
        ReactDOM.render(null, document.getElementById('modal'));
    }

    const createReasonPrompt = () => {
        let dropdownOptions = [
            'Size was too large',
            'Size was too small',
            'Product was defective',
            'Touchscreen did not work',
            'Product was not what I expected',
            'Other'
        ]
        return(
            <>
                <ParagraphWithHeader topText='Please provide the reason for your return or exchange:' />
                <TextInput label='This is a required field and requires an actual sentence. For example, “I need a larger size” is acceptable. “Asdf” or pressing the spacebar will VOID your exchange or return. ' required />
                {
                    (props.isMulti) ?
                        <Button backgroundColor='var(--indie-ridge-red)' textColor='white' buttonText='ADD' onClick={submitButtonMultiClick} />
                        :
                        <Button backgroundColor='var(--indie-ridge-red)' textColor='white' buttonText='SUBMIT' onClick={submitButtonClick} />
                }
            </>
        )
    }

    const createConfirmationText = () => {
        return (
            <>  
                <p>We have emailed you a pre-paid shipping label to send your item back to us. Please check your inbox to download and print your shipping label.</p>
                <p>It generally takes 1-2 business days to process a refund upon receipt of the returned item.</p>
            </>
        )
    }

    const createConfirmation = (data) => {
        return (
            <div className='ReturnConfirmationModal--Confirmation'>
                <div className='ReturnConfirmationModal--Checkmark'>
                  <img src={checkmark} alt='Checkmark' />
                </div>
                <ParagraphWithHeader topText='Return request submitted.' 
                                     bottomText={createConfirmationText()} />
                <a href={data.shipping_label_url}>Click here to download your shipping label.</a>
            </div>
        )
    }

    const miniModalYesButtonClick = () => {
        let dropdownOption = "Other";

        let dropdownElem = document.querySelector('.ReturnConfirmationModal .DropdownInput');
        if (dropdownElem) {
            dropdownOption = dropdownElem.value || "Other";
        }
        
        let freeResponse = document.querySelector('.ReturnConfirmationModal .TextInput').value;
        const endUrlParams = window.location.pathname.split('/').slice(-1)[0];
        if (endUrlParams.includes('-')){
            window.location.href = `/order/${props.order}/${endUrlParams}_${props.line_item_id}-return|${dropdownOption}|${freeResponse}`;
        } else {
            window.location.href = `/order/${props.order}/${props.line_item_id}-return|${dropdownOption}|${freeResponse}`;
        }
    }

    const miniModalNoButtonClick = () => {
        if (props.isMulti) {
            submitMultiForm();
        } else {
            submitReturnForm();
        }
    }

    const yesButtonClick = () => {
        ReactDOM.render(createReasonPrompt(), document.getElementById('ReturnConfirmationModal--MainContent'));
    }

    const submitMultiForm = () => {
        let url = window.location.origin + '/submit_multi_action_form';
        const inProgressExchangesReturns = window.location.pathname.split('/').slice(-1)[0];
        
        let dropdownOption = "Other";

        let dropdownElem = document.querySelector('.ReturnConfirmationModal .DropdownInput');
        if (dropdownElem) {
            dropdownOption = dropdownElem.value || "Other";
        }
        
        let freeResponse = document.querySelector('.ReturnConfirmationModal .TextInput').value;

        let body = {
            order: {
                order_id: props.order,
            },
            multi_action_formatted_string: {
                multi_action_formatted_string: `${inProgressExchangesReturns}_${props.line_item_id}-return%7C${dropdownOption}%7C${freeResponse}`
            },
            token: {
                tt: getCookie('tt')
            }
        }

        ReactDOM.render(<LoadingCircle />, document.getElementById('modal'));

        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        .then((response) => {
          if (response.status === 401) {
            window.location.href = '/';
          } else {
            return response.json();
          }
        })
        .then((data) => {
          if (data.error_message === 'Order not found.') {
            window.location.href = '/';
          } else {
            ReactDOM.render(createConfirmation(data), document.getElementById('modal'));
          }
        })
        .catch(error => {
            // handle the error
        });
    }

    const submitButtonMultiClick = () => {
        const inProgressExchangesReturns = window.location.pathname.split('/').slice(-1)[0];
        const currentActionsInProgress = inProgressExchangesReturns.includes('-') ? inProgressExchangesReturns.split('_') : [];
        if (props.orderSize > currentActionsInProgress.length + 1) {
            setMiniModal(true);
        } else if(currentActionsInProgress.length > 0) {
            submitMultiForm();
        } else {
            submitReturnForm();
        }
    }

    const submitButtonClick = () => {
        if (props.orderSize === 1) {
            submitReturnForm();
        } else {
            setMiniModal(true);
        }
    }

    const submitReturnForm = () => {
        let dropdownOption = "Other";

        let dropdownElem = document.querySelector('.ReturnConfirmationModal .DropdownInput');
        if (dropdownElem) {
            dropdownOption = dropdownElem.value || "Other";
        }        
        
        let freeResponse = document.querySelector('.ReturnConfirmationModal .TextInput').value;
        let url = window.location.origin + '/submit_return';

        let body = {
            order: {
                order_id: props.order
            },
            line_item: {
                line_item_id: props.line_item_id
            },
            dropdown: {
                dropdown_option: dropdownOption
            },
            free_response: {
                free_response: freeResponse
            },
            token: {
                tt: getCookie('tt')
            }
        }

        ReactDOM.render(<LoadingCircle />, document.getElementById('modal'));

        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        .then((response) => {
          if (response.status === 401) {
            window.location.href = '/';
          } else {
            return response.json();
          }
        })
        .then((data) => {
          if (data.error_message === 'Order not found.') {
            window.location.href = '/';
          } else {
            ReactDOM.render(createConfirmation(data), document.getElementById('modal'));
          }
        })
        .catch(error => {
            // handle the error
        });
    }

    return (
        <div className='ReturnConfirmationModal'>
            <div className='ReturnConfirmationModal--TopBar'>
                <button onClick={topBarCloseAction}>x</button>
            </div>
            <div id='ReturnConfirmationModal--MainContent'>
                <ParagraphWithHeader topText={paragraphHeader} />
                <ProductQuickInfoCell image={props.image}
                                      style={props.title} 
                                      size={props.variant_title} />
                <Button backgroundColor='var(--indie-ridge-red)' textColor='white' buttonText='YES' onClick={yesButtonClick} />
                <Button backgroundColor='white' border='1px solid var(--indie-ridge-grey)' textColor='var(--indie-ridge-grey)' buttonText='NO' onClick={topBarCloseAction} />
            </div>
            {
                miniModal ? (
                    <div className='ExchangeConfirmationMiniModal--outer'>
                        <div className='ExchangeConfirmationMiniModal--inner'>
                            <div>
                                <p className='centerMe'>Are you finished with<br />this order?</p>
                            </div>
                            <div>
                                <Button backgroundColor='white' 
                                        border='1px solid var(--indie-ridge-grey)' 
                                        textColor='var(--indie-ridge-grey)' 
                                        buttonText="NO, I'M NOT" 
                                        onClick={() => miniModalYesButtonClick()} />
                                <p className={'ExchangeConfirmationMiniModal--Button-Description'}>I need to Exchange/Return something<br /> else from this order.</p>
                                <Button backgroundColor='var(--indie-ridge-red)' 
                                        textColor='white' buttonText="YES, I'M DONE" 
                                        onClick={() => miniModalNoButtonClick()} />
                                <p className={'ExchangeConfirmationMiniModal--Button-Description'}>This will print your final <br/> shipping label for this order.</p>
                            </div>  
                        </div>
                    </div>
                ) : ''
            }
        </div>
    )
}  
