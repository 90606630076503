import React from 'react';
import './Header.css';

class Header extends React.Component {

    cartLogo = process.env.PUBLIC_URL + '/cart_icon.png';
    hamburgerLogo = process.env.PUBLIC_URL + '/burger_menu.png';
    redX = process.env.PUBLIC_URL + '/redX.png';

    websiteUrl = 'https://www.indieridgeusa.com/';
    cartUrl = this.websiteUrl + 'cart';

    links = [
        {title: 'motorcycle boots', links: [], url: this.websiteUrl + 'collections/boots'},
        {title: 'motorcycle gloves', links: [], url: this.websiteUrl + 'collections/all-gloves'},
        {title: 'accessories', links: [], url: this.websiteUrl + 'collections/face-masks'},
    ];

    mobile_links = [
        {title: 'contact us', links: [], url: this.websiteUrl + 'collections/face-masks'},
        {title: 'size exchanges & returns', links: [], url: 'https://exchanges.indieridgeusa.com/'}
    ]

    buttons = [
        {title: 'sign up', links: [], url: this.websiteUrl + 'account/register'},
        {title: 'login', links: [], url: this.websiteUrl + 'account/login'}
    ];

    getMenuItems = () => {
        let items = [];

        for(let i = 0; i < this.links.length; i++) {
          items.push(
            this.getSingleLink(this.links[i])
          );
        }

        return items;
    }

    getCaret = () => {
        return (
            <svg aria-hidden="true" focusable="false" role="presentation" className="icon icon-caret" viewBox="0 0 10 6">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.354.646a.5.5 0 00-.708 0L5 4.293 1.354.646a.5.5 0 00-.708.708l4 4a.5.5 0 00.708 0l4-4a.5.5 0 000-.708z" fill="currentColor" />
            </svg>
          );
    }

    getFlag = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="14" height="9" viewBox="0 0 28 18">
              <image id="Group_3" data-name="Group 3" width="28" height="18" xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAASCAYAAABIB77kAAAAVElEQVQ4jWM0s2z7z0ACmHG8ihTlGICJIt1kABZStRj+JylAMMDg9+F5RkaKLByNQ6oDxnMMDJQ5mURAfx/+/09hpJAIRuOQ6mA0DqkO6OtDBgYGANCCFoD/nOW/AAAAAElFTkSuQmCC"></image>
            </svg>
        );
    }

    getSingleLink = (link) => {
        return(
            <li>
                <a href={link.url} className="RobotoMedium">
                    <span>{link.title}</span>
                </a>  
            </li>
          );
    }

    getTopMenu = () => {
        return (
            <div className="Header--TopMenu">
                <div className="Header--TopMenu-InnerContainer">
                    <div className="Header--TopMenu-InnerLeft">
                      <a href="https://www.indieridgeusa.com/pages/contact-us">
                        <div className="Header--TopMenu-Contact">{this.getFlag()} Contact Us</div>
                      </a>
                    </div>
                    <div className="Header--TopMenu-InnerMiddle">
                        <div className="RobotoMedium">Free Shipping Over $100</div>
                    </div>
                    <div className="Header--TopMenu-InnerRight"></div>
                </div>
            </div>
        )
    }

    getDesktopMenu = () => {
      return (
        <div className="Header--Desktop Header--MainMenuContainer Header--BorderBottom">
          <div className="Header--MainMenu-Inner">
            <div className="Header--MainMenu-InnerLeft">
              <div className="Header--MainMenu-LogoContainer">
                <a href={this.websiteUrl}>
                  <img className="Header--logo" src="//cdn.shopify.com/s/files/1/1205/6736/t/39/assets/IndieRidge_Black_Simple_2%20(1).png?v=84312248615111272951666300793" />
                </a>
              </div>
              <ul className="Header--MainMenu">{this.getMenuItems()}</ul>
            </div>
            <div className="Header--MainMenu-InnerRight">
              <ul>
                {this.buttons.map( (button, index) => 
                   <li>
                    <a href={button.url}>
                      <button className={`Header--Button Header--ButtonColor${index + 1} RobotoMedium`}>{button.title}</button>
                    </a>
                   </li>
                )}
                <li>
                  <a href={this.cartUrl}>
                    <img className="Header--DesktopCart" src={this.cartLogo} />
                  </a>
                </li>
                  </ul>
            </div>
          </div>
        </div>
      )
    }

    getSidebarMenu = () => {
      return (
        <div className="Header--SidebarMenu-Container">
          <ul>
            {this.links.map( link => 
               <li>
                <a href={link.url} className="RobotoMedium">{link.title}</a>
               </li>
            )}
            {this.mobile_links.map( link => 
               <li>
                <a href={link.url} className="RobotoMedium">{link.title}</a>
               </li>
            )}
            {this.buttons.map( (button, index) => 
               <li className={`Header--SidebarMenu-Button${index + 1}`}>
                <a href={button.url}>
                  <button className={`Header--SidebarMenu-Button Header--SidebarMenu-ButtonColor${index + 1} RobotoMedium`}>{button.title}</button>
                </a>
               </li>
            )}
          </ul>
        </div>  
      )
    }

    showSidebarMenu = () => {
      document.querySelector('#Header--HamburgerContainer').style.display = 'none';
      document.querySelector('#Header--RedXContainer').style.display = 'block';
      document.querySelector('.Header--SidebarMenu-Container').style.left = '0px';
    }

    hideSidebarMenu = () => {
      document.querySelector('#Header--RedXContainer').style.display = 'none';
      document.querySelector('#Header--HamburgerContainer').style.display = 'block';
      document.querySelector('.Header--SidebarMenu-Container').style.left = '-100%';
    }

    getMobileMenu = () => {
      return (
        <>
          <div className="Header--Mobile Header--MobileMenuContainer Header--BorderBottom">
            <div className="Header--MobileNav-Button">
              <div id="Header--HamburgerContainer" onClick={this.showSidebarMenu}>
                <img className="Header--MobileHamburger" src={this.hamburgerLogo} />
              </div>
              <div id="Header--RedXContainer" onClick={this.hideSidebarMenu}>
                <img className="Header--MobileRedX" src={this.redX} />
              </div>
            </div>
            <div className="Header--MobileLogo-Container">
              <a href={this.websiteUrl}>
                <img className="Header--MobileLogo" src="//cdn.shopify.com/s/files/1/1205/6736/t/39/assets/IndieRidge_Black_Simple_2%20(1).png?v=84312248615111272951666300793" />
              </a>
            </div>
            <div className="Header--MobileCart-Container">
              <a href={this.cartUrl}>
                <img className="Header--MobileCart" src={this.cartLogo} />
              </a>
            </div>
          </div>
          <div className="Header--MobileNav">
          </div>
        </>
      )
    }

    render() {

        return (
            <>
              {this.getTopMenu()}
              {this.getDesktopMenu()}
              {this.getMobileMenu()}
              {this.getSidebarMenu()}
            </>
        );
    }
}

export default Header;

