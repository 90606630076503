import {React, useState} from 'react';
import ReactDOM from 'react-dom';
import './ExchangeConfirmationModal.css';

import ParagraphWithHeader from '../ParagraphWithHeader/ParagraphWithHeader';
import LoadingCircle from '../LoadingCircle/LoadingCircle';
import Button from '../Button/Button';
import ProductQuickInfoCell from '../ProductQuickInfoCell/ProductQuickInfoCell';
import SeparatorLine from '../SeparatorLine/SeparatorLine';
import AddressForm from '../AddressForm/AddressForm';

import { getCookie } from '../../util/cookieHelpers';

export default function ExchangeConfirmationModal(props) {
    const [paragraphHeader, setParagraphHeader] = useState('I WANT TO EXCHANGE:');
    const [checkmark, setCheckmark] = useState(process.env.PUBLIC_URL + '/check.png');
    const [miniModal, setMiniModal] = useState(false);

    const validateForm = (FirstName, LastName, AddressLine1, AddressZipcode, AddressState, AddressCity, AddressCountry) => {
        if (FirstName.length < 1, LastName.length < 1, AddressLine1.length < 2 || AddressZipcode.length < 2 || AddressState.length < 2 || AddressCity.length < 2 || AddressCountry.length < 2) {
            return false;
        }
        return true;
    }

    const submitForm = () => {
        const FirstName = document.getElementById("FirstName").value;
        const LastName = document.getElementById("LastName").value;
        const AddressLine1 = document.getElementById("AddressLine1").value;
        const AddressLine2 = document.getElementById("AddressLine2").value;
        const AddressZipcode = document.getElementById("AddressZipcode").value;
        const AddressState = document.getElementById("AddressState").value;
        const AddressCity = document.getElementById("AddressCity").value;
        const AddressCountry = document.getElementById("AddressCountry").value;

        if (validateForm(FirstName, LastName, AddressLine1, AddressZipcode, AddressState, AddressCity, AddressCountry)) {
            let url = window.location.origin + '/submit_magic_exchange';
            let body = {
                exchange_data: {
                    selectedProductId: props.dropdown_state.selectedProductId,
                    selectedTitle: props.dropdown_state.selectedTitle,
                    selectedVariantTitle: props.dropdown_state.selectedVariantTitle,
                    selectedVariantID: props.dropdown_state.selectedVariantID,
                    newImage: props.dropdown_state.newImage,
                    newProductTitle: props.dropdown_state.newProductTitle,
                    newVariantTitle: props.dropdown_state.newVariantTitle,
                    newVariantId: props.dropdown_state.newVariantId,
                    selectedImage: props.dropdown_state.selectedImage
                },
                token: {
                    tt: getCookie('tt')
                },
                shipping_data: {
                    FirstName: FirstName,
                    LastName: LastName,
                    AddressLine1: AddressLine1, 
                    AddressLine2: AddressLine2, 
                    AddressZipcode: AddressZipcode, 
                    AddressState: AddressState, 
                    AddressCity: AddressCity, 
                    AddressCountry: AddressCountry
                }
            }

            ReactDOM.render(<LoadingCircle />, document.getElementById('modal'));

            fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
            })
            .then((response) => {
              if (response.status === 401) {
                window.location.href = '/';
              } else {
                return response.json();
              }
            })
            .then((data) => {
              if (data.error_message === 'Order not found.') {
                window.location.href = '/';
              } else {
                console.log(data);
                ReactDOM.render(createConfirmation(data), document.getElementById('modal'));
              }
            })
            .catch(error => {
                // handle the error
            });  
        } else {
            alert('You must fill in all required fields before proceeding.');
        }
    }

    const topBarCloseAction = () => {
        document.getElementById('modal').style.display = 'none';
        ReactDOM.render(null, document.getElementById('modal'));
    }

    const createConfirmationText = () => {
        return (
            <>  
                <p>We have emailed you a pre-paid exchange label.</p>
                <p>Attach the shipping label to it's original shipping box and take it to your local UPS store.</p>
                <p>Once we receive your item, we will initiate the exchange.</p> 
                <p>Please allow 1-2 business days for the exchange to be processed.</p>
            </>
        )
    }

    const createConfirmation = (data) => {
        return (
            <div className='ExchangeConfirmationModal--Confirmation'>
                <div className='ExchangeConfirmationModal--Checkmark'>
                  <img src={checkmark} alt='Checkmark' />
                </div>
                <ParagraphWithHeader topText='EXCHANGE REQUEST COMPLETED' 
                                     bottomText={createConfirmationText()} />
                <a href={data.shipping_label_url}>Click here to download your shipping label.</a>
            </div>
        )
    }

    const createAddressForm = () => {
        return (
            <>
                <AddressForm />
                <Button backgroundColor='var(--indie-ridge-red)' textColor='white' buttonText='SUBMIT' onClick={submitForm} />
            </>
        )
    }

    const submitMultiForm = () => {
        let url = window.location.origin + '/submit_multi_action_form';
        const inProgressExchangesReturns = window.location.pathname.split('/').slice(-1)[0];

        let body = {
            order: {
                order_id: props.orderId,
            },
            multi_action_formatted_string: {
                multi_action_formatted_string: `${inProgressExchangesReturns}-${props.newVariantId}`
            },
            token: {
                tt: getCookie('tt')
            }
        }

        ReactDOM.render(<LoadingCircle />, document.getElementById('modal'));

        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        .then((response) => {
          if (response.status === 401) {
            window.location.href = '/';
          } else {
            return response.json();
          }
        })
        .then((data) => {
          if (data.error_message === 'Order not found.') {
            window.location.href = '/';
          } else {
            ReactDOM.render(createConfirmation(data), document.getElementById('modal'));
          }
        })
        .catch(error => {
            // handle the error
        });
    }

    const yesButtonClick = () => {
        if (window.location.href.includes('magic')) {
            miniModalNoButtonClick();
        } else {
            const inProgressExchangesReturns = window.location.pathname.split('/').slice(-1)[0];
            const currentActionsInProgress = inProgressExchangesReturns.includes('_') ? inProgressExchangesReturns.split('_') : [];
            if (props.totalOrderSize > currentActionsInProgress.length + 1) {
                setMiniModal(true);
            } else if(currentActionsInProgress.length > 0) {
                submitMultiForm();
            } else {
                submitExchangeForm();
            }
        }
    }

    const submitExchangeForm = () => {
        let url = '';
        let body = {};

        if (props.magic){
            ReactDOM.render(createAddressForm(), document.getElementById('ExchangeConfirmationModal--MainContent'));
        } else {
            url = window.location.origin + '/submit_exchange';

            body = {
                order: {
                    order_id: props.orderId,
                },
                line_item: {
                    line_item_id: props.lineItemID
                },
                exchange_data: {
                    selectedProductId: props.dropdown_state.selectedProductId,
                    selectedTitle: props.dropdown_state.selectedTitle,
                    selectedVariantTitle: props.dropdown_state.selectedVariantTitle,
                    selectedVariantID: props.dropdown_state.selectedVariantID,
                    newImage: props.dropdown_state.newImage,
                    newProductTitle: props.dropdown_state.newProductTitle,
                    newVariantTitle: props.dropdown_state.newVariantTitle,
                    newVariantId: props.dropdown_state.newVariantId,
                    selectedImage: props.dropdown_state.selectedImage
                },
                token: {
                    tt: getCookie('tt')
                }
            }

            ReactDOM.render(<LoadingCircle />, document.getElementById('modal'));

            fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
            })
            .then((response) => {
              if (response.status === 401) {
                window.location.href = '/';
              } else {
                return response.json();
              }
            })
            .then((data) => {
              if (data.error_message === 'Order not found.') {
                window.location.href = '/';
              } else {
                ReactDOM.render(createConfirmation(data), document.getElementById('modal'));
              }
            })
            .catch(error => {
                // handle the error
            });
        }
    }

    const miniModalYesButtonClick = () => {
        const endUrlParams = window.location.pathname.split('/').slice(-1)[0];
        if (endUrlParams.includes('_')){
            window.location.href = `/order/${props.orderId}/${endUrlParams}-${props.newVariantId}`;
        } else {
            window.location.href = `/order/${props.orderId}/${props.lineItemID}-${props.newVariantId}`;
        }
    }

    const miniModalNoButtonClick = () => {
        submitExchangeForm();
    }

    return (
        <>
            <div className='ExchangeConfirmationModal'>
                <div className='ExchangeConfirmationModal--TopBar'>
                    <button onClick={() => topBarCloseAction()} className='ExchangeConfirmationModal--CloseButton'></button>
                </div>
                <div id='ExchangeConfirmationModal--MainContent'>
                    <ParagraphWithHeader className='ExchangeConfirmationModal--center' topText={paragraphHeader} />
                    <ParagraphWithHeader topText='YOUR CURRENT ITEM:' />
                    <ProductQuickInfoCell image={props.dropdown_state.selectedImage}
                                          style={props.dropdown_state.selectedTitle} 
                                          size={props.dropdown_state.selectedVariantTitle} />
                    <SeparatorLine full={true} />
                    <ParagraphWithHeader topText='YOUR NEW ITEM:' />
                    <ProductQuickInfoCell image={props.dropdown_state.newImage}
                                          style={props.dropdown_state.newProductTitle} 
                                          size={props.dropdown_state.newVariantTitle} />
                    <ParagraphWithHeader className='ExchangeConfirmationModal--center' bottomText='Is this correct?' />
                    <Button backgroundColor='var(--indie-ridge-red)' textColor='white' buttonText='YES' onClick={() => yesButtonClick()} />
                    <Button backgroundColor='white' border='1px solid var(--indie-ridge-grey)' textColor='var(--indie-ridge-grey)' buttonText='NO' onClick={() => topBarCloseAction()} />
                </div>
            </div>
            {
                miniModal ? (
                    <div className='ExchangeConfirmationMiniModal--outer'>
                        <div className='ExchangeConfirmationMiniModal--inner'>
                            <div>
                                <p className='centerMe'>Are you finished with<br />this order?</p>
                            </div>
                            <div>
                                <Button backgroundColor='white' 
                                        border='1px solid var(--indie-ridge-grey)' 
                                        textColor='var(--indie-ridge-grey)' 
                                        buttonText="NO, I'M NOT" 
                                        onClick={() => miniModalYesButtonClick()} />
                                <p className={'ExchangeConfirmationMiniModal--Button-Description'}>I need to Exchange/Return something<br /> else from this order.</p>
                                <Button backgroundColor='var(--indie-ridge-red)' 
                                        textColor='white' buttonText="YES, I'M DONE" 
                                        onClick={() => miniModalNoButtonClick()} />
                                <p className={'ExchangeConfirmationMiniModal--Button-Description'}>This will print your final <br/> shipping label for this order.</p>
                            </div>  
                        </div>
                    </div>
                ) : ''
            }
        </>
    )
}  
