import React from 'react';
import ReactDOM from 'react-dom';
import './MagicExchange.css';

import ProductQuickInfoCell from '../../Components/ProductQuickInfoCell/ProductQuickInfoCell';
import ParagraphWithHeader from '../../Components/ParagraphWithHeader/ParagraphWithHeader';
import TextHeaderWithBackground from '../../Components/TextHeaderWithBackground/TextHeaderWithBackground';
import SeparatorLine from '../../Components/SeparatorLine/SeparatorLine';
import ImageDropdownInput from '../../Components/ImageDropdownInput/ImageDropdownInput';
import HorizontalButtonGroup from '../../Components/HorizontalButtonGroup/HorizontalButtonGroup';
import LoadingCircle from '../../Components/LoadingCircle/LoadingCircle';
import Button from '../../Components/Button/Button';

class MagicExchange extends React.Component {
    getCookie = (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    firstChangeSize = (componentState) => {
        this.setState({
            selectedImage: componentState.selectedProductImage,
            selectedTitle: componentState.selectedProductTitle,
            selectedVariantTitle: componentState.selectedVariantTitle,
            selectedVariantID: componentState.selectedVariantId
        });
        document.querySelector('#Exchange--Magictrue').style.display = 'flex';
    }

    firstSubmitButtonClick = () => {
        window.location.href = '/exchange/magic/' + this.state.selectedVariantID;
    }

    cancelButtonClick = () => {
        window.location.href = '/';
    }

    getLineItemData = () => {
        let url = window.location.origin + "/collections";

        let bodyData = {
            tt: this.getCookie('tt')
        }

        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
        })
        .then((response) => {
            if (response.status === 401) {
                window.location.href = '/';
            } else {
                return response.json();
            }
        })
        .then((data) => {
          console.log(data);
          this.setState({
            selectedProductId: data.boots[0].id,
            selectedTitle: data.boots[0].name,
            selectedVariantTitle: data.boots[0].variants[0].variant_title,
            selectedVariantID: data.boots[0].variants[0].id,
            selectedImage: data.boots[0].image.src,

            gloveData: data.gloves,
            bootData: data.boots
          }, () => {
                ReactDOM.render(<ImageDropdownInput mainImage={data.boots[0].image.src} 
                                                    similar_products={data.boots.concat(data.gloves)}
                                                    variants={data.boots[0].variants}
                                                    selectedProductTitle={data.boots[0].title}
                                                    selectedVariantTitle={data.boots[0].variants[0].variant_title}
                                                    selectedProductId={data.boots[0].id}
                                                    selectedVariantId={data.boots[0].variants[0].id}
                                                    isMagic={true}
                                                    onChange={this.firstChangeSize} />, document.getElementById('ProductOptions'));
          });
        })
        .catch(error => {
            console.log(error);
        });
    }

    renderFirst = () => {
        // ParagraphWithHeader 
        const topText = 'GIFT OR AMAZON EXCHANGES';
        const bottomText = <p>Select the product you currently have and would like exchanged. <br /> <br /> For boots names, check the tongue:</p>;
        const bottomTextClass = 'Exchange--BottomText';

        // SeparatorLine
        const fullLine = true;

        // Submit Button
        const submitButtonColor = "var(--indie-ridge-red)";
        const submitButtonTextColor = "white";
        const submitButtonText = "CONTINUE";

        // Cancel Button
        const cancelButtonColor = "white";
        const cancelButtonTextColor = "var(--indie-ridge-grey)";
        const cancelButtonText = "CANCEL";
        const cancelButtonBorder = "solid 1px var(--indie-ridge-grey)";

        // TextHeaderWithBackground
        const backgroundColor = 'black';
        const textColor = 'white';

        return(
            <div className="Exchange">
                <TextHeaderWithBackground backgroundColor={backgroundColor} textColor={textColor} />
                <div className="ExchangeInner">
                    <ParagraphWithHeader topText={topText} />
                    <ParagraphWithHeader className={bottomTextClass} bottomText={bottomText} />
                    <div id="ProductOptions">
                        <LoadingCircle />
                    </div>
                    <div id="Exchange--Magictrue">
                        <HorizontalButtonGroup
                            firstButton={<Button backgroundColor={submitButtonColor}
                                                 textColor={submitButtonTextColor}
                                                 buttonText={submitButtonText}
                                                 onClick={this.firstSubmitButtonClick} />}
                            secondButton={<Button backgroundColor={cancelButtonColor}
                                                  textColor={cancelButtonTextColor}
                                                  buttonText={cancelButtonText}
                                                  border={cancelButtonBorder}
                                                  onClick={this.cancelButtonClick} />} />
                    </div>
                </div>
            </div>
        )
    }

    constructor(props) {
        super(props);
        
        this.state = {
            selectedProductId: '',
            selectedTitle: '',
            selectedVariantTitle: '',
            selectedVariantID: ''
        };
        this.getLineItemData();
    }

    render() {
        return (
            <div id="MagicExchange">
                {this.renderFirst()}
            </div>
        )
    }
}

export default MagicExchange;
