import React from 'react';
import './SeparatorLine.css';

class SeparatorLine extends React.Component {

    render() {
        return (
            <>
                <hr className={`SeparatorLine--Full-${this.props.full}`} />
            </>
        )
    }
}

export default SeparatorLine;
  
