import React from 'react';
import './TextHeaderWithBackground.css';
import BackButton from '../BackButton/BackButton';

class TextHeaderWithBackground extends React.Component {
    render() {
        return (
            <div className="TextHeaderWithBackground" style={{ backgroundColor: `${this.props.backgroundColor}` }}>
                <div className="TextHeaderWithBackground--third">
                    <BackButton />
                </div>
                <div className="TextHeaderWithBackground--third TextHeaderWithBackground--TextContainer">
                    <div className="TextHeaderWithBackground--text RobotoMedium" style={{ color: `${this.props.textColor}` }}>{this.props.topText}</div>
                </div>
                <div className="TextHeaderWithBackground--third">
                </div>
            </div>
        )
    }
}

export default TextHeaderWithBackground;
  
