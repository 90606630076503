import React from 'react';
import { Link } from 'react-router-dom';
import './OrderCell.css';

class OrderCell extends React.Component {
    months = {
        '01': 'January',
        '02': 'February',
        '03': 'March',
        '04': 'April',
        '05': 'May',
        '06': 'June',
        '07': 'July',
        '08': 'August',
        '09': 'September',
        '10': 'October',
        '11': 'November',
        '12': 'December'
    }

    timeArray = this.props.orderData.created_at.split('T')
    dateArray = this.timeArray[0].split('-')
    newDate = `${this.months[this.dateArray[1]]} ${this.dateArray[2]}, ${this.dateArray[0]}`

    renderExchangeMessage = () => {
        if (this.props.orderData.has_exchange) {
            return (
                <>
                    <div class="OrderCell--HasExchange">
                        <span>Contains exchange</span>
                    </div>
                </>
            )
        }
    }

    renderReturnMessage = () => {
        if (this.props.orderData.has_return) {
            return (
                <>
                    <div class="OrderCell--HasReturn">
                        <span>Contains return</span>
                    </div>
                </>
            )
        }
    }

    render() {
        return (
            <Link to={this.props.link} className="OrderCell--Link">
                <div className="OrderCell">
                    <div className="OrderCell--Left">
                        <div className="OrderCell--id RobotoMedium">Order number: #{this.props.orderData.order_number}</div>
                        <div className="OrderCell--price Roboto">Order total: ${this.props.orderData.total_price}</div>
                        <div className="OrderCell--items Roboto">Contains {this.props.orderData.line_items} {this.props.orderData.line_items > 1 ? "items" : "item"}</div>
                    </div>
                    <div className="OrderCell--Right">
                        <div className="OrderCell--date Roboto">
                            <span>Order placed: </span>
                            <span>{this.newDate}</span>
                        </div>
                    </div>
                </div>
            </Link>
        )
    }
}

export default OrderCell;
  
