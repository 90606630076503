import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import './OrderSearch.css';

import ParagraphWithHeader from '../../Components/ParagraphWithHeader/ParagraphWithHeader';
import EmailInput from '../../Components/EmailInput/EmailInput';
import OrderTable from '../../Components/OrderTable/OrderTable';
import LoadingCircle from '../../Components/LoadingCircle/LoadingCircle';
import Button from '../../Components/Button/Button';

class OrderSearch extends React.Component {

    getCookie = (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    buttonClick = () => {
        let url = window.location.origin + '/search_order_history';
        let bodyData = {
            token: {
                tt: this.getCookie('tt')
            },
            email: {
                email: document.getElementById('input_email').value
            }
        }

        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
        })
        .then((response) => {
          if (response.status === 401) {
            window.location.href = '/';
          } else {
            return response.json();
          }
        })
        .then((data) => {
          console.log(data);
          if (data.error_message === 'Order not found.') {
            alert('Customer not found.');
          } else {
            this.setState({
                orders: data.order_history
            });
          } 
        })
        .catch(error => {
            // handle the error
        });
    }

    constructor(props) {
        super(props);

        this.state = {
          orders: []
        }
    }

    render() {
        return (
            <div className="OrderSearch">
                <ParagraphWithHeader topText="Please enter the email you would like to look up." bottomText="You will see their order history."/>
                <EmailInput id="input_email" onEnter={this.buttonClick} />
                <Button backgroundColor='var(--indie-ridge-red)' textColor='white' buttonText='Search' onClick={this.buttonClick} />
                <Link to='/magic_exchange'>
                  <Button backgroundColor='var(--indie-ridge-red)' textColor='white' buttonText='Gift Exchange' />
                </Link>
                <p style={{textAlign: 'center'}}>Customer total orders: {this.state.orders.length} </p>
                <OrderTable orders={this.state.orders} />
            </div>
        )
    }
}

export default OrderSearch;
