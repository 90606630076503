import React from 'react';
import './AddressForm.css';

import ParagraphWithHeader from '../ParagraphWithHeader/ParagraphWithHeader';

export default class AddressForm extends React.Component {

  handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace();
    let address = addressObject.address_components;

    const address1 = 'AddressLine1';
    const zip = 'AddressZipcode';
    const state = 'AddressState';
    const city = 'AddressCity';
    const country = 'AddressCountry';

    var ad1_v = ""; 
    var zip_v = ""; 
    var state_v = "";
    var city_v = ""; 
    var country_v = "US";

    for (var i = 0; i < address.length; i++) {
      for (var j = 0; j < address[i].types.length; j++) {
        if (address[i].types[j] === "locality") {
          city_v = address[i].short_name;
        } else if (address[i].types[j] === "postal_code") {
          zip_v = address[i].short_name;
        } else if (address[i].types[j] === "administrative_area_level_1") {
          state_v = address[i].short_name;
        } else if (address[i].types[j] === "street_number") {
          ad1_v = ad1_v + address[i].long_name;
        } else if (address[i].types[j] === "route") {
          ad1_v = ad1_v + " " + address[i].short_name;
        }
      }
    }

    document.getElementById(address1).value = ad1_v;
    document.getElementById(zip).value = zip_v;
    document.getElementById(state).value = state_v;
    document.getElementById(city).value = city_v;
    document.getElementById(country).value = country_v;
  }

  addressDidChange() {
    const address1 = 'AddressLine1';
    const autocomplete = 'AutoComplete';
    document.getElementById(address1).value = document.getElementById(autocomplete).value;
  }

  componentDidMount() {
    const google = window.google;
    this.autocomplete = new google.maps.places.Autocomplete(document.getElementById(`AutoComplete`), {})
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect)
  }

  constructor(props) {
    super(props);
    this.autocomplete = null;
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.addressDidChange = this.addressDidChange.bind(this);
  }

  render() {
    return (
      <div className="AddressForm">
        <ParagraphWithHeader topText='Enter your shipping address.' bottomText='We need this information to send out your new product once the old one is received.' />
        <div className='AddressSpecialFieldContainer'>
          <input id={`FirstName`} className="AddressSpecialField FirstName" placeholder="First Name (required)" />
          <input id={`LastName`} className="AddressSpecialField LastName" placeholder="Last Name (required)" />
        </div>
        <input id={`AutoComplete`} onChange={this.addressDidChange} className="AddressField AddressAutocomplete" placeholder="Type Your Address Here...  (required)" />
        <input id={`AddressLine1`} className="AddressField AddressLine1" placeholder="Address line 1 (required)" />
        <input id={`AddressLine2`} className="AddressField AddressLine2" placeholder="Address line 2" />
        <input id={`AddressCity`} className="AddressField AddressCity" placeholder="City (required)" />
        <div className='AddressSpecialFieldContainer'>
          <input id={`AddressState`} className="AddressField AddressState" placeholder="State (required)" />
          <input id={`AddressZipcode`} className="AddressField AddressZipcode" placeholder="Zipcode (required)" />
        </div>
        <input id={`AddressCountry`} className="AddressField AddressCountry" placeholder="Country (required)" />
      </div>
    )
  }
}
