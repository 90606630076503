import {React, useState} from 'react';
import './DataTable.css';

export default function DataTable(props) {

  const [selectedHeaderSort, setSelectedHeaderSort] =  useState('Created');
  const [selectedSortDirection, setSelectedSortDirection] = useState('Ascending');

  const calculateDiscountColor = (discountApplications) => {
    if (discountApplications && discountApplications.length > 0){
      var totalValue = 0;
      for (var i = 0; i < discountApplications.length; i++) {
        totalValue += discountApplications[i].value;
      }
      if (totalValue <= 10) {
        return(' discount_low');
      } else if (totalValue > 10 && totalValue <= 15) {
        return(' discount_med');
      } else if (totalValue > 15) {
        return(' discount_high');
      }
    }
    return('')
  }

	return (
    <div className={'MainTableWrapper'}>
  		<table cellspacing={0} id={'DataTable'} className={'DataTable'}>
  		  <tr>
  		  	{
  		  		props.headers.map( (header, index) => (
  		  				<th className={
                    `${header !== 'Name' && header !== 'Email' ? 'th_center' : ''}` + 
                    ` ${header.replace(' ', '_')}` +
                    `${header === ' Email' ? ' th_email' : ''}` + 
                    `${index === 0 ? ' th_start' : ''}` +
                    `${index === props.headers.length - 1 ? ' th_end' : ''}` +
                    `${selectedHeaderSort === header ? ' selectedHeader' : ''}` +
                    `${header === 'Exc' || header === 'Ref' || header === '🚚' || header === 'Rej' ? ' small-th': ''}` +
                    `${selectedHeaderSort === header && 
                       selectedSortDirection === 'Descending' ? ' arrow-up' : 
                         selectedHeaderSort === header && 
                         selectedSortDirection === 'Ascending' ? ' arrow-down' : '' }`}
                    onClick={() => {
                      setSelectedSortDirection(props.sortBy(header));
                      if (header !== 'Track' && header !== 'Label' && header !== '') {
                        setSelectedHeaderSort(header);
                      }
                    }}>{header}</th>
  		  			)
  		  		)
  		  	}
  		  </tr>
  		  {
  	  		props.table.map( 
  	  			(
  	  				{id, name, order, order_id, received, exchanged, refunded, rejected, item, newItem, tracking_number, email, dateCreated, discountApplications, totalActions},
            	index,
            ) => (
  	  				<tr>
                <td onClick={(e) => {
                  if (e.target.id !== `r_${id}`) {
                    document.querySelector(`input#r_${id}`).click()
                  }
                }} className={'td_start'}> 
                  <input id={`r_${id}`} className={'datarow'} data-action-id={id} type="checkbox" onClick={() => {
                      props.selectCallback();
                  }} />
                </td>

                <td className={'th_center'}>
  	              <button className={'DataLink' + calculateDiscountColor(discountApplications)} onClick={() => {
  	                window.open(`https://admin.shopify.com/store/indie-ridge/orders/${order_id}`, '_blank').focus();
  	              }}>
  	                {order}
  	              </button>
                </td>

                <td onClick={(e) => {
                  if (e.target.id !== `r_${id}`) {
                    document.querySelector(`input#r_${id}`).click()
                  }
                }}>{name}</td>
  	              
                <td className={'th_center'}>
                  {totalActions}
                </td>

  	            <td className={'th_center'}>
  	              {
  	                received ? 
  	                  '✅'
  	                : '❌'
  	              }
  	            </td>

  	            <td className={'th_center'}>
  	              {
  	                exchanged ? 
  	                  '✅'
  	                : exchanged === false ? '❌' : '-'
  	              }
  	            </td>

  	            <td className={'th_center'}>
  	              {
  	                refunded ? 
  	                  '✅'
  	                : refunded === false ? '❌' : '-'
  	              }
  	            </td>

                <td className={'th_center'}>
                  {
                    rejected ? 
                      '✅'
                    : rejected === false ? '❌' : '-'
                  }
                </td>

  	            <td className={'th_center'}>{item}</td>
  	            <td className={'th_center'}>{newItem}</td>

                <td className={'th_center'}>
                  <button className={'DataLink'} onClick={() => {
                    window.open(`https://www.google.com/search?q=${tracking_number}`, '_blank').focus();
                  }}>
                    Track
                  </button>
                </td>
                
  	            <td>{email}</td>

                <td className={'th_center'}>{dateCreated}</td>

                <td className={'td_end th_center'}>
                  <button className={'DataLink'} onClick={() => {
                   props.trackingHandler(tracking_number);
                  }}>
                    Print
                  </button>
                </td>
  	            
  					  </tr>
  	  			)
  	  		)
  	  	}
  		</table>
    </div>
	)

}