import React from 'react';
import { Link } from 'react-router-dom';
import './OrderHistory.css';

import OrderTable from '../../Components/OrderTable/OrderTable';
import ParagraphWithHeader from '../../Components/ParagraphWithHeader/ParagraphWithHeader';
import Button from '../../Components/Button/Button';
import LoadingCircle from '../../Components/LoadingCircle/LoadingCircle';

class OrderHistory extends React.Component {

    // Paragraph With Header
    paragraphHeader = 'ORDER HISTORY';
    paragraphText = 'Select the order you would like to exchange or return.';
    bottomTextBorder = true;

    getCookie = (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    getOrderHistory = () => {
        let url = window.location.origin + '/order_history';
        let bodyData = {
            tt: this.getCookie('tt')
        }

        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
        })
        .then((response) => {
          if (response.status === 401) {
            window.location.href = '/';
          } else {
            return response.json();
          }
        })
        .then((data) => {
          if (data.error_message === 'Order not found.') {
            window.location.href = '/';
          } else if (data.order_history.length > 0) {
            this.setState({
                orders: data.order_history,
                loading: false
            });
          } else {
            window.location.href = '/magic_exchange';
          }
        })
        .catch(error => {
            // handle the error
        });
    }

    constructor(props) {
        super(props);

        this.state = {
          orders: [],
          loading: true
        }

        this.getOrderHistory();
    }

    render() {
        return (
            <div className="OrderHistory">
              { this.state.loading ? <LoadingCircle /> : 
                <> 
                  <ParagraphWithHeader topText={this.paragraphHeader} bottomText={this.paragraphText} bottomTextBorder={this.bottomTextBorder} />
                  <OrderTable orders={this.state.orders} />  
                </>
              }
            </div> 
        )
    }
}

export default OrderHistory;
  
