import React from 'react';
import './EmailInput.css';

class EmailInput extends React.Component {

    handleKeyPress = (e) => {
        if (e.key === "Enter") {
            this.props.onEnter();
        }
    }

    render() {
        return (
            <div className="EmailInput">
                <input type="text" className="EmailInputField" placeholder="Submit Email Address" onKeyPress={(e) => this.handleKeyPress(e)} id={this.props.id} />
            </div>
        )
    }
}

export default EmailInput;
  
