import React from 'react';
import './Button.css';

class Button extends React.Component {

	render() {
    return (
      <div className="Button">
        <button onClick={this.props.onClick} className={`ButtonField RobotoMedium ` + this.props.className} style={{
          backgroundColor: `${this.props.backgroundColor}`,
          color: `${this.props.textColor}`,
          border: `${this.props.border}`
        }}>{this.props.buttonText}</button>
      </div>
    )
	}
}

export default Button;
