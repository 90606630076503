import React from 'react';
import ReactDOM from 'react-dom';
import './Exchange.css';

import ProductQuickInfoCell from '../../Components/ProductQuickInfoCell/ProductQuickInfoCell';
import ParagraphWithHeader from '../../Components/ParagraphWithHeader/ParagraphWithHeader';
import TextHeaderWithBackground from '../../Components/TextHeaderWithBackground/TextHeaderWithBackground';
import SeparatorLine from '../../Components/SeparatorLine/SeparatorLine';
import ImageDropdownInput from '../../Components/ImageDropdownInput/ImageDropdownInput';
import HorizontalButtonGroup from '../../Components/HorizontalButtonGroup/HorizontalButtonGroup';
import LoadingCircle from '../../Components/LoadingCircle/LoadingCircle';
import Button from '../../Components/Button/Button';
import ExchangeConfirmationModal from '../../Components/ExchangeConfirmationModal/ExchangeConfirmationModal';

import { getCookie } from '../../util/cookieHelpers';

class Exchange extends React.Component {
    orderId = '';
    lineItemID = '';
    variantID = '';

    // ParagraphWithHeader 
    topText = 'YOUR CURRENT ITEM:';
    bottomText = 'Choose the size and/or style you want.';
    bottomTextClass = 'Exchange--BottomText';

    // SeparatorLine
    fullLine = true;

    // Submit Button
    submitButtonColor = "var(--indie-ridge-red)";
    submitButtonTextColor = "white";
    submitButtonText = "SUBMIT";

    // Cancel Button
    cancelButtonColor = "white";
    cancelButtonTextColor = "var(--indie-ridge-grey)";
    cancelButtonText = "CANCEL";
    cancelButtonBorder = "solid 1px var(--indie-ridge-grey)";

    // TextHeaderWithBackground
    backgroundColor = 'black';
    textColor = 'white';

    // Image
    checkmark = process.env.PUBLIC_URL + '/check.png';

    changeSize = (componentState) => {
        this.setState({
            newImage: componentState.selectedProductImage,
            newProductTitle: componentState.selectedProductTitle,
            newVariantTitle: componentState.selectedVariantTitle,
            newVariantId: componentState.selectedVariantId
        });
    }

    submitButtonClick = () => {
        document.getElementById('modal').style.display = 'flex';
        ReactDOM.render(<ExchangeConfirmationModal magic={this.props.magic} 
                                                   dropdown_state={this.state} 
                                                   orderId={this.orderId} 
                                                   lineItemID={this.lineItemID}
                                                   newVariantId={this.state.newVariantId}
                                                   totalOrderSize={this.state.totalOrderSize} />, document.getElementById('modal'));
    }

    cancelButtonClick = () => {
        document.querySelector('.BackButton').click();
    }

    getProductData = () => {
        let url = window.location.origin + "/product_details";

        let bodyData = {
          token: {
            tt: getCookie('tt')
          },
          variant_id: {
            variant_id: this.variantID,
          }
        }

        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
        })
        .then((response) => {
            if (response.status === 401) {
                window.location.href = '/';
            } else {
                return response.json();
            }
        })
        .then((data) => {
          this.setState({
            selectedProductId: data.variant.product_id,
            selectedTitle: data.product.title,
            selectedVariantTitle: data.variant.title,
            selectedVariantID: data.variant.id,
            selectedImage: data.product.image.src,

            newImage: data.product.image.src,
            newProductTitle: data.product.title,
            newVariantTitle: data.variant.title,
            newVariantId: data.variant.id
          }, () => {
                ReactDOM.render(<ProductQuickInfoCell image={data.product.image.src}
                                        style={data.product.title} 
                                        size={data.variant.title} />, document.getElementById('ProductImage'));

                ReactDOM.render(<ImageDropdownInput mainImage={data.product.image.src} 
                                                    similar_products={data.similar_products}
                                                    variants={data.product.variants}
                                                    selectedProductTitle={data.product.title}
                                                    selectedVariantTitle={data.variant.title}
                                                    selectedProductId={data.product.id}
                                                    selectedVariantId={data.variant.id}
                                                    onChange={this.changeSize} />, document.getElementById('ProductOptions'));
          });
        })
        .catch(error => {
            console.log(error);
        });
    }

    getLineItemData = () => {
        let url = window.location.origin + "/item_details";

        let bodyData = {
          token: {
            tt: getCookie('tt')
          },
          order: {
            order_id: this.orderId,
          },
          line_item: {
            line_item_id: this.lineItemID
          }
        }

        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
        })
        .then((response) => {
            if (response.status === 401) {
                window.location.href = '/';
            } else {
                return response.json();
            }
        })
        .then((data) => {
          this.setState({
            selectedProductId: data.line_item.product_id,
            selectedTitle: data.line_item.name,
            selectedVariantTitle: data.line_item.variant_title,
            selectedVariantID: data.line_item.variant_id,
            selectedImage: data.line_item.image.src,

            newImage: data.line_item.image.src,
            newProductTitle: data.line_item.title,
            newVariantTitle: data.line_item.variant_title,
            newVariantId: data.line_item.variant_id,
            totalOrderSize: data.line_item.total_order_size
          }, () => {
                ReactDOM.render(<ProductQuickInfoCell image={data.line_item.image.src}
                                        style={data.line_item.title} 
                                        size={data.line_item.variant_title} />, document.getElementById('ProductImage'));

                ReactDOM.render(<ImageDropdownInput mainImage={data.line_item.image.src} 
                                                    similar_products={data.similar_products}
                                                    variants={data.line_item.variants}
                                                    selectedProductTitle={data.line_item.title}
                                                    selectedVariantTitle={data.line_item.variant_title}
                                                    selectedProductId={data.line_item.product_id}
                                                    selectedVariantId={data.line_item.variant_id}
                                                    onChange={this.changeSize} />, document.getElementById('ProductOptions'));
          });
        })
        .catch(error => {
            console.log(error);
        });
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedProductId: '',
            selectedTitle: '',
            selectedVariantTitle: '',
            selectedVariantID: '',
            newImage: '',
            newProductTitle: '',
            newVariantTitle: '',
            newVariantId: '',
            totalOrderSize: 0
        };

        if (this.props.magic) {
            this.variantID = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
            this.getProductData();
        } else {
            this.orderId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
            let tempLineItemID = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
            if (tempLineItemID.includes('_')) {
                this.lineItemID = tempLineItemID.split('_').slice(-1)[0];
            } else {
                this.lineItemID = tempLineItemID;
            }
            this.getLineItemData();
        }
    }

    render() {
        return (
            <div className="Exchange">
                <TextHeaderWithBackground topText='' backgroundColor={this.backgroundColor} textColor={this.textColor} />
                <div className="ExchangeInner">
                    <ParagraphWithHeader topText={this.topText} />
                    <div id="ProductImage">
                        <LoadingCircle />
                    </div>
                    <SeparatorLine full={this.fullLine} />
                    <ParagraphWithHeader className={this.bottomTextClass} bottomText={this.bottomText} />
                    <div id="ProductOptions">
                        <LoadingCircle />
                    </div>
                    <HorizontalButtonGroup
                        firstButton={<Button backgroundColor={this.submitButtonColor}
                                             textColor={this.submitButtonTextColor}
                                             buttonText={this.submitButtonText}
                                             onClick={this.submitButtonClick} />}
                        secondButton={<Button backgroundColor={this.cancelButtonColor}
                                              textColor={this.cancelButtonTextColor}
                                              buttonText={this.cancelButtonText}
                                              border={this.cancelButtonBorder}
                                              onClick={this.cancelButtonClick} />} />
                </div>
            </div>
        )
    }
}

export default Exchange;
  
