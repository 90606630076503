import React from 'react';
import './AuthEmail.css';

class AuthEmail extends React.Component {

    setCookie = (cname, cvalue, exmins) => {
        const d = new Date();
        d.setTime(d.getTime() + (exmins*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    getCookie = (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    athy = () => {        
        let url = window.location.origin + "/tt" + window.location.search;

        fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: '',
          })
          .then((response) => response.json())
          .then((data) => {
            if (data.detail === 'Unknown Entity') {
                window.location.href = '/';
            } else {
                if (data.search_flag) {
                    this.setCookie('tt', data.access_token, 60*24*7);
                    window.location.href = '/admin';
                } else {
                    this.setCookie('tt', data.access_token, 60);
                    window.location.href = '/orders';
                }
            }
          })
          .catch(error => {
            window.location.href = '/';
          });
    }

    // Send to /tt to get token, redirect to '/' if no token, redirect to '/orders' if there is.
    // change all urls to include token query parameters so the server can authenticate the token. 

    render() {
        this.athy();
        return (
            <div className="PleaseWait">
                <p>Please wait while we redirect you to your dashboard...</p>
            </div>
        )
    }
}

export default AuthEmail;
  
