import React from 'react';
import ReactDOM from 'react-dom';
import './Homepage.css';

import HeroImageWithText from '../../Components/HeroImageWithText/HeroImageWithText';
import ParagraphWithHeader from '../../Components/ParagraphWithHeader/ParagraphWithHeader';
import EmailInput from '../../Components/EmailInput/EmailInput';
import Button from '../../Components/Button/Button';
import LoadingCircle from '../../Components/LoadingCircle/LoadingCircle';

//  BACK BUTTON

class Homepage extends React.Component {

  // Hero Image With Text
  backgroundImageDesktop = 'https://cdn.shopify.com/s/files/1/1205/6736/files/Collection_Marquee_Boots_desktop_1700x.jpg?v=1666217442';
  backgroundImageMobile = process.env.PUBLIC_URL + '/main_hero.png';
  imageTopText = 'SIZE EXCHANGES & RETURNS';
  imageBottomText = ``;

  // Paragraph With Header
  paragraphHeader = 'Size Exchange & Return Policy';
  paragraphText = <p>Items purchased directly through www.indieridgeusa.com can be Exchanged and/or Returned. <br /> <br /> For more details, review our <a style={{color: 'var(--indie-ridge-red)'}} href="https://www.indieridgeusa.com/pages/the-road-house-return-policy">Exchange and Return Policy.</a></p>;

  // Email Input
  inputId = "email";

  // Button
  buttonColor = "var(--indie-ridge-red)";
  buttonTextColor = "white";
  buttonText = "SUBMIT";

  // Image
  checkmark = process.env.PUBLIC_URL + '/check.png';

  getCookie = (cname) => {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
  }

  getConfirmation = () => {
    let confirmationHeader = 'Confirmed. Thank you.';
    let confirmationText = 'Please check your email and click the link to start your exchange or return.';
    let confirmationClass = 'Homepage--Confirmation';

    return(
      <>
        <div className='Homepage--Checkmark'>
          <img src={this.checkmark} alt='Checkmark' />
        </div>
        <ParagraphWithHeader className={confirmationClass} topText={confirmationHeader} bottomText={confirmationText} />
      </>
    )
  }

  buttonClick = () => {
    let inputEmail = document.getElementById(this.inputId).value;
    let url = window.location.href + "ml";
    let bodyData = {
      email: inputEmail
    }

    ReactDOM.render(<LoadingCircle />, document.getElementById('Homepage-Main'));

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyData),
    })
    .then((response) => response.json())
    .then((data) => {
      ReactDOM.render(this.getConfirmation(), document.getElementById('Homepage-Main'));
    })
    .catch(error => {
        window.location.href = '/';
    });

  }

  render() {
    return (
      <div className="Homepage">
        <HeroImageWithText className="Homepage--Desktop" imageUrl={this.backgroundImageDesktop} topText={this.imageTopText} bottomText={this.imageBottomText} />
        <HeroImageWithText className="Homepage--Mobile" imageUrl={this.backgroundImageMobile} topText={this.imageTopText} bottomText={this.imageBottomText} />
        <div id="Homepage-Main">
          <ParagraphWithHeader topText={this.paragraphHeader} bottomText={this.paragraphText} />
          <EmailInput id="email" onEnter={this.buttonClick} />
          <Button backgroundColor={this.buttonColor} textColor={this.buttonTextColor} buttonText={this.buttonText} onClick={this.buttonClick} />
        </div>
      </div>
    )
  }
}

export default Homepage;
  
