import {React, useState} from 'react';
import ReactDOM from 'react-dom';
import './MultiConfirmationModal.css';

import ParagraphWithHeader from '../ParagraphWithHeader/ParagraphWithHeader';
import Button from '../Button/Button';
import LoadingCircle from '../LoadingCircle/LoadingCircle';

import { getCookie } from '../../util/cookieHelpers';

export default function MultiConfirmationModal(props) {

    const paragraphHeader = 'Are you finished with this order?';
    const paragraphText = 'This will submit your completed returns/exchanges and provide you with a shipping label.';

    const createConfirmationText = () => {
        return (
            <>  
                <p>We have emailed you a pre-paid shipping label to send your item back to us. Please check your inbox to download and print your shipping label.</p>
                <p>It generally takes 1-2 business days to process a refund upon receipt of the returned merchandise.</p>
            </>
        )
    }

    const checkmark = process.env.PUBLIC_URL + '/check.png';

    const createConfirmation = (data) => {
        return (
            <div className='MultiConfirmationModal--Confirmation'>
                <div className='MultiConfirmationModal--Checkmark'>
                  <img src={checkmark} alt='Checkmark' />
                </div>
                <ParagraphWithHeader topText='Your request has been submitted.' 
                                     bottomText={createConfirmationText()} />
                <a href={data.shipping_label_url}>Click here to download your shipping label.</a>
            </div>
        )
    }

	const yesButtonClick = () => {
		let url = window.location.origin + '/submit_multi_action_form';
        const inProgressExchangesReturns = window.location.pathname.split('/').slice(-1)[0];

        let body = {
            order: {
                order_id: props.order,
            },
            multi_action_formatted_string: {
                multi_action_formatted_string: inProgressExchangesReturns
            },
            token: {
                tt: getCookie('tt')
            }
        }

        ReactDOM.render(<LoadingCircle />, document.getElementById('modal'));

        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        .then((response) => {
          if (response.status === 401) {
            window.location.href = '/';
          } else {
            return response.json();
          }
        })
        .then((data) => {
          if (data.error_message === 'Order not found.') {
            window.location.href = '/';
          } else {
            ReactDOM.render(createConfirmation(data), document.getElementById('modal'));
          }
        })
        .catch(error => {
            // handle the error
        });
    }

	const topBarCloseAction = () => {
        document.getElementById('modal').style.display = 'none';
        ReactDOM.render(null, document.getElementById('modal'));
    }

	return (
      <div className='MultiConfirmationModal'>
        <div className='MultiConfirmationModal--TopBar'>
          <button onClick={topBarCloseAction}>x</button>
        </div>
        <div id='MultiConfirmationModal--MainContent'>
          <ParagraphWithHeader topText={paragraphHeader} bottomText={paragraphText} />
          <Button backgroundColor='var(--indie-ridge-red)' textColor='white' buttonText='YES' onClick={yesButtonClick} />
          <Button backgroundColor='white' border='1px solid var(--indie-ridge-grey)' textColor='var(--indie-ridge-grey)' buttonText='NO' onClick={topBarCloseAction} />
        </div>
      </div>
    )
}