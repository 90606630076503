import React from 'react';
import './ParagraphWithHeader.css';

class ParagraphWithHeader extends React.Component {

    render() {
        return (
            <div className={`ParagraphWithHeader ${this.props.className}`}>
                {this.props.topText && <div className="ParagraphWithHeader--TopText RobotoMedium">{this.props.topText}</div>}
                {this.props.bottomText && <div className={`ParagraphWithHeader--BottomText Roboto ParagraphWithHeader--BottomTextBorder-${this.props.bottomTextBorder}` }>{this.props.bottomText}</div>}
            </div>
        )
    }
}

export default ParagraphWithHeader;
  
