import React from 'react';
import './OrderInformation.css';

class OrderInformation extends React.Component {
    render() {
        return (
            <div className="OrderInformation">
                <div className="OrderInformation--Header RobotoMedium">Order Details</div>
                <div className="OrderInformation--OuterContainer">
                    <div className="Roboto">Name</div>
                    <div className="Roboto">{this.props.name}</div>
                </div>
                <div className="OrderInformation--OuterContainer">
                    <div className="Roboto">Email Address</div>
                    <div className="Roboto">{this.props.email}</div>
                </div>
                <div className="OrderInformation--OuterContainer">
                    <div className="Roboto">Date of Purchase</div>
                    <div className="Roboto">{this.props.processed_at}</div>
                </div>
                <div className="OrderInformation--OuterContainer">
                    <div className="Roboto">Total Price</div>
                    <div className="Roboto">${this.props.total_price}</div>
                </div>
                <div className="OrderInformation--OuterContainer">
                    <div className="Roboto">Phone Number</div>
                    <div className="Roboto">{this.props.phone}</div>
                </div>
                <div className="OrderInformation--OuterContainer">
                    <div className="Roboto">Delivery Address</div>
                    <div className="OrderInformation--InnerContainer">
                        <span className="Roboto">{this.props.address_one}</span>
                        <span className="Roboto">{this.props.address_two}</span>
                        <span className="Roboto">{this.props.city}, {this.props.province}</span>
                        <span className="Roboto">{this.props.zip}</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default OrderInformation;
  
