import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import ReturnConfirmationModal from '../ReturnConfirmationModal/ReturnConfirmationModal';
import './LineItemCell.css';


class LineItemCell extends React.Component {

    // Exchange Button
    exchangeButtonColor = "var(--indie-ridge-red)";
    exchangeButtonTextColor = "white";
    exchangeButtonText = "EXCHANGE";
    

    // Refund Button
    refundButtonColor = "white";
    refundButtonTextColor = "var(--indie-ridge-red)";
    refundButtonText = "RETURN";
    refundButtonBorder = "solid 1px var(--indie-ridge-grey)";

    buttonClass = "LineItemCell--Button";

    refundAction = (lineItem) => {
        if (this.props.isMulti){
            console.log('multi');
            ReactDOM.render(<ReturnConfirmationModal image={this.props.imageUrl} 
                                                     title={this.props.title} 
                                                     variant_title={this.props.variantTitle}
                                                     isMulti={this.props.isMulti}
                                                     order={this.props.order}
                                                     orderSize={this.props.orderTotalSize}
                                                     line_item_id={lineItem.id} />, document.getElementById('modal'));
            document.getElementById('modal').style.display = 'flex';
        } else {
            ReactDOM.render(<ReturnConfirmationModal image={this.props.imageUrl} 
                                                     title={this.props.title} 
                                                     variant_title={this.props.variantTitle}
                                                     order={this.props.order}
                                                     orderSize={this.props.orderTotalSize}
                                                     line_item_id={lineItem.id} />, document.getElementById('modal'));
            document.getElementById('modal').style.display = 'flex';
        }
    }

    checkWhitelist = (product_id) => {
        return this.props.whitelist.has(product_id);
    }

    renderExchangeButton = () => {
        let isWhitelisted = this.checkWhitelist(this.props.productId);
        // const unusedLinks = <> <span className="LineItemCell--DateInfo">20{this.props.lineItem.exchange.exchange_created.split('T')[0]}</span> <a className="LineItemCell--URL LineItemCell--TrackingURL" href={this.props.lineItem.exchange.exchange_tracking_url}><span>Package tracking</span></a> <a className="LineItemCell--URL" href={this.props.lineItem.exchange.exchange_shipping_label_url}><span>Shipping label</span></a> </>;

        if (isWhitelisted && this.props.lineItem.refund === false && this.props.lineItem.exchange === false) {
            return (
                <>
                    <Link to={this.props.link} className="LineItemCell--Link">
                        <div>
                            <Button className={this.buttonClass}
                                    backgroundColor={this.exchangeButtonColor} 
                                    textColor={this.exchangeButtonTextColor} 
                                    buttonText={this.exchangeButtonText} />
                        </div>
                    </Link>
                </>
            )
        } else if (isWhitelisted && this.props.lineItem.exchange !== false) {
            return (
                <>
                    <div className="LineItemCell--ExtraInfoContainer">
                        <span className="LineItemCell--DateInfo">Exchange request pending...</span>
                    </div>
                </>
            )
        }

        return (<> </>)
    }

    renderReturnButton = () => {
        // const unusedLinks = <> <span className="LineItemCell--DateInfo">20{this.props.lineItem.refund.refund_created.split('T')[0]}</span> <a className="LineItemCell--URL LineItemCell--TrackingURL" href={this.props.lineItem.refund.refund_tracking_url}><span>Package tracking</span></a> <a className="LineItemCell--URL" href={this.props.lineItem.refund.refund_shipping_label_url}><span>Shipping label</span></a> </>;

        if (this.props.lineItem.refund === false){
            return (
                <>
                    <div>
                        <Button className={this.buttonClass}
                                backgroundColor={this.refundButtonColor} 
                                textColor={this.refundButtonTextColor} 
                                buttonText={this.refundButtonText} 
                                onClick={() => this.refundAction(this.props.lineItem)}
                                border={this.refundButtonBorder} />
                    </div>
                </>
            )
        } else if (this.props.lineItem.refund !== false) {
            return (
                <>
                    <div className="LineItemCell--ExtraInfoContainer">
                        <span className="LineItemCell--DateInfo">Refund request pending...</span>
                    </div>
                </>
            )
        }
    }

    constructor(props) {
        super(props);
        console.log(`processed ${this.props.processed}`);
    }

    render() {
        return (
            <div className={`LineItemCell ${this.props.border ? 'LineItemCell--Border' : ''}`}>
                <div className="LineItemCell--Third">
                    <img className="LineItemCell--img" src={this.props.imageUrl} alt={this.props.title} />
                </div>
                <div className="LineItemCell--Third LineItemCell--TextContainer">
                    <div className="Roboto">{this.props.title}</div>
                    <div className="Roboto"></div>
                    <div className="Roboto">{this.props.variantTitle}</div>
                    <div className="Roboto">${this.props.price}</div>
                </div>
                {
                    this.props.processed ? 
                        <div className="LineItemCell--Third">
                            <Button className={`${this.buttonClass} disabled`}
                                    backgroundColor={'rgba(0,0,0,0.3)'}
                                    textColor={'white'}
                                    buttonText={'Pending'}
                                    onClick={() => {}} />
                            <Button className={this.buttonClass}
                                    backgroundColor={this.refundButtonColor}
                                    textColor={this.refundButtonTextColor}
                                    buttonText={'Cancel'}
                                    border={this.refundButtonBorder}
                                    onClick={() => {window.location.href = `/order/${this.props.order}`}} />
                        </div>
                    :
                        <div className="LineItemCell--Third">
                            {this.renderExchangeButton()}
                            {this.renderReturnButton()}
                        </div>
                }
            </div>
        )
    }
}

export default LineItemCell;
  
