import React from 'react';
import './HeroImageWithText.css';

class HeroImageWithText extends React.Component {

    render() {
        return (
            <div className={'HeroImageWithText ' + this.props.className} style={{
                backgroundImage: `url(${this.props.imageUrl})`
            }}>
                <div className="HeroImageWithText--TextContainer">
                    <div className="HeroImageWithText--TopText RobotoMedium">{this.props.topText ? this.props.topText : ''}</div>
                    <div className="HeroImageWithText--BottomText RobotoMedium">{this.props.bottomText ? this.props.bottomText : ''}</div>
                </div>
            </div>
        )
    }
}

export default HeroImageWithText;
  
