import React from 'react';
import { useNavigate } from "react-router-dom";

import './BackButton.css';

const BackButton = () => {
  const navigate = useNavigate();
    
  return (
      <>
        <button className="BackButton" onClick={()=>navigate(-1)}></button>
      </>
  )
};
  
export default BackButton;
