import React from 'react';
import './LoadingCircle.css';

class LoadingCircle extends React.Component {

    render() {
      return (
        <div className="LoadingCircle">
            <svg className="load" x="0px" y="0px" viewBox="0 0 150 150">
                <circle className="loading-inner" cx="75" cy="75" r="60"/>
                </svg>
        </div>
      );
    }
}

export default LoadingCircle;
